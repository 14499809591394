import axios from 'axios'
import { notification } from 'antd'
import { addLoading, subLoading } from '../utils/loading'
import { getCookie } from '../utils/cookie'
import { useNavigate } from 'react-router-dom'
import { BASE_URL_OBJ } from './url'
notification.config({ maxCount: 1 })
console.log(process.env.REACT_APP_SECRET_API)

axios.defaults.headers.common['Content-Type'] = 'application/json'
const instance = axios.create({
	baseURL: BASE_URL_OBJ[process.env.REACT_APP_SECRET_API],
	timeout: 20000,
})
instance.interceptors.request.use((config) => {
	addLoading()
	console.log(config)
	config.headers.token = getCookie('token')
	return config
})
instance.interceptors.response.use(
	(response) => {
		console.log(response, 'dddd')
		subLoading()
		try {
			if (response.data.code === 200) {
				return response.data.data
			} else if (response.data.code === 403) {
				window.location.href = window.location.href.split('?')[0] + '403'
				throw new Error()
			} else {
				throw new Error()
			}
		} catch (error) {
			notification.error({
				message: response.data.msg || '网络错误',
				duration: 2,
			})
			throw new Error('网络错误')
		}
	},
	(err) => {
		console.log(err)
		subLoading()
		notification.error({
			message: '网络错误',
			duration: 2,
		})
		throw new Error('网络错误')
	}
)
const Get = (url, params) => instance.get(url, { params: params || {} })
const Post = (url, data) => instance.post(url, data || {})

export { Get, Post }
