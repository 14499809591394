import { Modal, List, Input, Button } from 'antd'
import React, { useEffect, useState } from 'react'
import { ModalBtns } from './ModalBtns'
export function CompleteModal({ visible, onCancel, onOk, list, type }) {
	return (
		<Modal
			width={420}
			open={visible}
			centered
			onCancel={onCancel}
			title="内容不完善"
			footer={[]}
			className="complete-modal"
			destroyOnClose={true}
		>
			<>
				<div className="complete-view">
					<div className="sub-text">以下内容不完善，请修改后发布</div>
					<div className="list">
						{list.map((item) => {
							return (
								<div key={item.id} className="item">
									流程设计：<span className="blue-text">{item.content}</span>
								</div>
							)
						})}
					</div>
				</div>
				<ModalBtns
					rightText="知道了"
					// rightText="立即修改"
					// onCancel={onCancel}
					onClick={onOk}
					hasLeft={false}
				/>
			</>
		</Modal>
	)
}
