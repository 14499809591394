import React from 'react'
import { Tooltip } from 'antd'
import { register } from '@antv/x6-react-shape'
const bgColorObj = {
	userTask: '#FF8800',
	toCC: '#3370FF',
}
const contentTextObj = {
	userTask: '请设置审批人',
	toCC: '请设置抄送人',
}
export class CommonNode extends React.Component {
	render() {
		// console.log(this.props.node.store.data.data)
		const { nodeName, nodeContent, nodeType } = this.props.node.store.data.data

		return (
			<div
				style={{
					backgroundColor: bgColorObj[nodeType],
				}}
				className="common-node"
			>
				<Tooltip title={nodeName}>
					<span className="title">{nodeName}</span>
				</Tooltip>
				<div className="content-view">
					{nodeContent ? (
						<span>{nodeContent}</span>
					) : (
						<span className="gray-text">{contentTextObj[nodeType]}</span>
					)}
				</div>
				{!nodeContent && <span className="iconfont error">&#xe6e5;</span>}
			</div>
		)
	}
}
register({
	shape: 'common-node',
	width: 200,
	height: 78,
	component: CommonNode,
})
