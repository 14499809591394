const types = [
	{ label: '人工审核', value: 0 },
	{ label: '自动通过', value: 1, disabled: true },
	{ label: '自动拒绝', value: 2, disabled: true },
]
const typesOptions = [
	{ label: '设置审批人', value: 0 },
	{ label: '操作权限', value: 1, disabled: true },
]
const roles = [
	{ label: '指定成员', value: 0 },
	{ label: '连续多级上级', value: 1, disabled: true },
	{
		label: '部门负责人',
		value: 2,
		hasTip: true,
		content:
			'什么是部门负责人？ 管理员在组织架构中指定的部门唯一负责人。 部门负责人与直属上级的区别？ 一个部门内可能存在多层的上下级关系',
	},
	{ label: '直属上级', value: 3, disabled: true },
	{
		label: '角色',
		value: 4,
		hasTip: true,
		content: `什么是角色？
角色指团队成员的专业分工类别，如人事、行政、财务等，每类角色可由 1 位或多位成员组成
如何使用？
用角色作为审.批人，当有成员离职变动时，该角色中的其他成员可继续完成审批，从而避免审批流程失效的情况
提示：若选择的角色中包含多名成员，则按照设置“多人审批时采用的审批方式”来处理'`,
	},
	{
		label: '发起人自己',
		value: 5,
		hasTip: true,
		content: `什么是提交人审批？
  将提交人自己设置为审批人，可用于需要提交人进行信息复核的场景`,
	},
]

const manyMans = [
	{ label: '依次审批（按顺序依次审批）', value: '1' },
	{ label: '会签（需所有审批人同意）', value: '2' },
	{ label: '或签（一名审批人同意即可）', value: '3' },
]
const twoMans = [
	{ label: '会签（需所有审批人同意）', value: '2' },
	{ label: '或签（一名审批人同意即可）', value: '3' },
]
const emptyOption = [
	{ label: '自动通过', value: 1 },
	{
		label: '自动转交管理员',
		value: 2,
		hasTip: true,
		content: `什么是转交给审批管理员？
  若审批人为空，则自动转交给该审批流程的管理员
  `,
	},
	{ label: '指定人员审批', value: 3 },
]
const empty_text = `什么情况下会出现审批人为空？
设置了“上级”审批，但申请人在管理后台 - 组织架构中没有上级
设置了“部门负责人”审批，但申请人在管理后台 - 组织架构中没有部门负责人
设置了“角色”审批，但该角色在管理后台 - 组织架构中没有任何成员
设置了“用户组”审批，但该用户组在飞书管理后台 - 组织架构中没有任何成员`
const endOption = [
	{ label: '指定角色', value: 1 },
	{ label: '通讯录中的', value: 2 },
]
const appointOptions = [
	{ label: '第1级主管', value: 1 },
	{ label: '第2级主管', value: 2 },
	{ label: '第3级主管', value: 3 },
]
const bookOptions = [
	{ label: '第高层级主管', value: 9999 },
	{ label: '第2层级主管', value: 2 },
	{ label: '第3层级主管', value: 3 },
]
const departmentOptions = [
	{ label: '直接主管', value: 1 },
	{ label: '第2级主管', value: 2 },
	{ label: '第3级主管', value: 3 },
]
const initApproveFnc = (obj = {}) => {
	if (!obj.approvalType) {
		obj.approvalType = 0
	}
	if (!obj.propertyType) {
		obj.propertyType = 0
	}
	if (!obj.isSequential) {
		obj.isSequential = '2'
	}
	if (!obj.users) {
		obj.users = []
	}
	if (obj.toBeApproved === 1) {
		obj.toBeApproved = [0]
	} else {
		obj.toBeApproved = []
	}
	if (!obj.level) {
		obj.level = 1
	}
	if (!obj.isPass) {
		obj.isPass = 1
	}
	if (!obj.approveEnd) {
		obj.approveEnd = 1
	}
	if (!obj.contactLeve) {
		obj.contactLeve = 9999
	}
	if (!obj.roleIsSequential) {
		obj.roleIsSequential = '2'
	}
	return obj
}
export {
	types,
	typesOptions,
	roles,
	manyMans,
	emptyOption,
	empty_text,
	endOption,
	appointOptions,
	bookOptions,
	departmentOptions,
	initApproveFnc,
	twoMans,
}
