import { mark_ori } from '../assets/index'
import { Button, Modal } from 'antd'
const icons = [mark_ori]
export function ModalCommon({
	visible,
	centered = true,
	tip1,
	tip2,
	iconType = 0, // 0 橙色叹号
	onCancel = () => {},
	onOk = () => {},
	blueBtn = false,
	btnText = '确定',
	footer = [
		<Button key="cancel" onClick={onCancel} className="width-80 margin-12">
			取消
		</Button>,
		!blueBtn ? (
			<Button key="ok" onClick={onOk} className="ant-btn-custom-red width-80">
				{btnText}
			</Button>
		) : (
			<Button key="ok" onClick={onOk} type="primary" className="width-80">
				{btnText}
			</Button>
		),
	],
}) {
	return (
		<Modal
			open={visible}
			className="common-modal"
			centered={centered}
			onCancel={onCancel}
			footer={null}
		>
			<div className="modalView">
				<div className="modalTitle">
					<img src={icons[iconType]} className="image" />
					<span className="tip">{tip1}</span>
				</div>
				{tip2 && <div className="tip2">{tip2}</div>}
				<div className="btns">{footer}</div>
			</div>
		</Modal>
	)
}
