import { Modal } from 'antd'
import { shenpi, chaosong, tiaojian, banli } from '../assets/index'
export function AddModal({ visible, onCancel, btnClick, location }) {
	return (
		<Modal
			// centered
			width={370}
			className="add-modal"
			style={{
				position: 'absolute',
				left: location.clientX + 10,
				top: location.clientY + 10,
			}}
			onCancel={onCancel}
			footer={[]}
			open={visible}
			maskStyle={{ backgroundColor: 'transparent' }}
		>
			<div className="modal-content">
				<div className="item-view">
					<div onClick={() => btnClick(0)} className="item">
						<div className="left">
							<img src={shenpi} className="image" />
						</div>
						<div className="text">审批人</div>
					</div>
					<div onClick={() => btnClick(1)} className="item">
						<div className="left" style={{ backgroundColor: '#3370FF' }}>
							<img src={chaosong} className="image" />
						</div>
						<div className="text">抄送人</div>
					</div>
				</div>

				<div className="item-view" style={{ marginTop: 10 }}>
					{/* <div onClick={() => btnClick(2)} className="item">
						<div className="left" style={{ backgroundColor: '#935AF6' }}>
							<img src={banli} className="image" />
						</div>
						<div className="text">办理人</div>
					</div> */}
					<div onClick={() => btnClick(3)} className="item">
						<div className="left" style={{ backgroundColor: '#00D6BA' }}>
							<img src={tiaojian} className="image" />
						</div>
						<div className="text">条件分支</div>
					</div>
				</div>
			</div>
		</Modal>
	)
}
