import { Select, Input } from 'antd'
export function SelectView({
	expressionOptions,
	sValue,
	iValue,
	mainKey,
	id,
	sChange,
	iChange,
}) {
	const selectChange = (v) => {
		sChange(v, mainKey, id)
	}
	const inputChange = (e) => {
		iChange(e.target.value, mainKey, id)
	}
	return (
		<div className="select-type">
			<Select
				value={sValue}
				onChange={selectChange}
				className="select-left"
				options={expressionOptions}
				placeholder="请选择"
			></Select>
			<div className="select-blank"></div>
			<Input
				value={iValue}
				onChange={inputChange}
				className="select-right"
				placeholder="请输入"
			></Input>
		</div>
	)
}
