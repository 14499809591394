import { Button } from 'antd'
export function ModalBottomBtns({
	onCancel,
	leftText = '取消',
	rightText = '确定',
	style = {},
}) {
	return (
		<div style={style} className="modal-bottom-btns">
			<Button onClick={onCancel} className="width-80 margin-12">
				{leftText}
			</Button>
			<Button className="width-80" htmlType="submit" type="primary">
				{rightText}
			</Button>
		</div>
	)
}
