import { no_permission } from './assets/index'

export function NoPermission() {
	return (
		<div className="no-permission">
			<img className="image" src={no_permission}></img>
			<span className="tip">暂无访问权限</span>
		</div>
	)
}
