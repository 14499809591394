import { Button } from 'antd'
export function ModalBtns({
	onCancel = () => {},
	leftText = '取消',
	rightText = '确定',
	style = {},
	onClick,
	hasLeft = true,
}) {
	return (
		<div style={style} className="modal-bottom-flex-end-btns">
			{hasLeft && (
				<Button onClick={onCancel} className="width-80 margin-12">
					{leftText}
				</Button>
			)}
			<Button onClick={onClick} className="width-80" type="primary">
				{rightText}
			</Button>
		</div>
	)
}
