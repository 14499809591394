import { Input } from 'antd'
import { edit_gray } from '../assets/index'

export function DrawerEditTitle({ onChange, onClick, title, canEdit, onBlur }) {
	return (
		<div className="start-drawer-top">
			{canEdit ? (
				<Input
					onChange={onChange}
					value={title}
					className="input"
					placeholder="请输入"
					onBlur={onBlur}
					maxLength={20}
				></Input>
			) : (
				<>
					<span className="text">{title}</span>
					<span onClick={onClick} className="iconfont image">
						&#xe6b2;
					</span>
				</>
			)}
		</div>
	)
}
