import { Transfer, Modal, List, Input, Button } from 'antd'
import React, { useEffect, useState, useRef } from 'react'
import VirtualList from 'rc-virtual-list'
import { select_empty, select_yes } from '../assets/index'
import { findDep, definition_groupList } from '../api/x6'
import { linears } from '../App.data'
import { ModalBtns } from './ModalBtns'
import { TwoSelectView } from './TwoSelectView'
import { NavView } from './NavView'
function findTargetArr(arr = [], key) {
	let result = arr.filter((item) => item.deptId === key)
	if (result.length === 1) {
		return result[0].childrenDept
	}
	console.log(arr, key)
	for (let i = 0; i < arr.length; i++) {
		console.log(i, arr[i].childrenDept)
		if (arr[i].childrenDept) {
			let value = findTargetArr(arr[i].childrenDept, key)
			console.log(value)
			if (value) {
				return value
			}
		}
	}
}
function flattenOriArr(arr = [], targetArr = []) {
	arr.map((item) => {
		targetArr.push(item)
		if (item.childrenDept) {
			flattenOriArr(item.childrenDept, targetArr)
		}
	})
}
export function TwoTargetModal({ visible, onCancel, onOk, title, tList = [] }) {
	const [targetKeys, setTargetKeys] = useState(tList)
	const [leftArr, setLeftArr] = useState([])
	const [selectIndex, setSelectIndex] = useState(0)
	const [navArr, setNavArr] = useState([])
	const [leftAllList, setLeftAllList] = useState([])
	const [inputValue, setInputValue] = useState()
	const flattenRef = useRef([])
	const snapshootRef = useRef()
	const roleOriListRef = useRef()
	useEffect(() => {
		definition_groupList()
			.then((_res) => {
				let roleList = _res.records.map((_item) => {
					return { deptId: _item.roleCode, deptName: _item.roleName }
				})
				findDep()
					.then((res) => {
						let mockList = [
							{ deptId: 'mock', deptName: '通讯录', childrenDept: res },
						]
						let flattenArr = []
						flattenOriArr(res, flattenArr)
						flattenRef.current = [...flattenArr, ...roleList]
						roleOriListRef.current = roleList
						setLeftAllList(mockList)
						setLeftArr(mockList[0].childrenDept)
						snapshootRef.current = mockList[0].childrenDept
						setNavArr([
							{ deptId: mockList[0].deptId, deptName: mockList[0].deptName },
						])
					})
					.catch(() => {})
			})
			.catch(() => {})
	}, [])
	const _onOk = () => {
		console.log(targetKeys)
		let send_text = targetKeys
			.map((item) => {
				let tmpList = flattenRef.current.filter((v) => v.deptId === item)
				if (tmpList.length === 1) {
					return tmpList[0].deptName
				}
			})
			.filter((item) => item)
			.join('、')
		onOk(targetKeys, send_text)
	}
	const leftItemClick = (item) => {
		let isLeftHas = targetKeys.includes(item.deptId)
		console.log(isLeftHas, '------')
		if (isLeftHas) {
			setTargetKeys(targetKeys.filter((v) => v !== item.deptId))
		} else {
			setTargetKeys([...targetKeys, item.deptId])
		}
	}
	// 等待接口数据返回否则置空
	const rightList =
		flattenRef.current.length > 0
			? targetKeys
					.map((item) => flattenRef.current.filter((v) => v.deptId === item)[0])
					.filter((item) => item)
			: []
	const rigthDelete = (id) => {
		setTargetKeys(targetKeys.filter((v) => v !== id))
	}
	const selectAllClick = () => {
		let isSelect = leftArr.every((item) => targetKeys.includes(item.deptId))
		if (!isSelect) {
			// 全部勾选
			leftArr.map((item) => {
				if (!targetKeys.includes(item.deptId)) {
					targetKeys.push(item.deptId)
				}
			})
		} else {
			// 全部取消勾选
			leftArr.map((item) => {
				if (targetKeys.includes(item.deptId)) {
					let index = targetKeys.findIndex((v) => v === item.deptId)
					targetKeys.splice(index, 1)
				}
			})
		}
		setTargetKeys([...targetKeys])
	}
	const inputChange = (v) => {
		setInputValue(v.target.value)
		if (!v.target.value) {
			if (selectIndex === 0) {
				setLeftArr(snapshootRef.current)
			} else {
				setLeftArr(roleOriListRef.current)
			}
		} else {
			setLeftArr(
				flattenRef.current.filter(
					(item) => item.deptName.indexOf(v.target.value) > -1
				)
			)
		}
	}
	const leftClick = () => {
		if (selectIndex === 0) return
		setInputValue('')
		setSelectIndex(0)
		setLeftArr(snapshootRef.current)
	}
	const rightClick = () => {
		if (selectIndex === 1) return
		setInputValue('')
		setSelectIndex(1)
		setLeftArr(roleOriListRef.current)
	}
	const nextClick = (e, item) => {
		e.stopPropagation()
		setNavArr([...navArr, { deptId: item.deptId, deptName: item.deptName }])
		setLeftArr([...item.childrenDept])
		snapshootRef.current = [...item.childrenDept]
	}
	const navClick = (item, index) => {
		if (navArr.length === index + 1) return
		setNavArr(navArr.slice(0, index + 1))
		let tmpArr = findTargetArr(leftAllList, item.deptId)
		setLeftArr(tmpArr)
		snapshootRef.current = tmpArr
	}
	console.log(rightList, 'ssss')
	return (
		<Modal
			width={900}
			className="single-list-modal"
			open={visible}
			centered
			onCancel={onCancel}
			title={title}
			footer={[]}
		>
			<>
				<div style={{ height: 400 }} className="target-contain">
					<div className="target-item">
						<Input
							prefix={<span className="iconfont search-icon">&#xe6ac;</span>}
							onChange={inputChange}
							placeholder="部门、角色搜索"
							value={inputValue}
						></Input>
						<TwoSelectView
							leftClick={leftClick}
							rightClick={rightClick}
							selectIndex={selectIndex}
						/>
						{selectIndex === 0 && (
							<NavView onClick={navClick} navArr={navArr} />
						)}
						{selectIndex === 0 && (
							<div onClick={selectAllClick} className="left-all">
								<img
									className="select-icon"
									src={
										leftArr.length > 0 &&
										leftArr.every((item) => targetKeys.includes(item.deptId))
											? select_yes
											: select_empty
									}
								></img>
								<span>全部</span>
							</div>
						)}
						<List split={false}>
							<VirtualList
								data={leftArr}
								height={selectIndex === 0 ? 220 : 300}
								itemHeight={48}
								itemKey="deptId"
							>
								{(item, index) => (
									<List.Item key={item.deptId}>
										<div
											onClick={() => leftItemClick(item)}
											className="list-item"
										>
											<div className="left-view">
												<img
													className="select-icon"
													src={
														targetKeys.includes(item.deptId)
															? select_yes
															: select_empty
													}
												></img>
												<div
													className="image"
													style={{ background: linears[index % 3] }}
												>
													<span className="title">
														{item.deptName.slice(0, 2)}
													</span>
												</div>
												<span>{item.deptName}</span>
											</div>
											{item.childrenDept && (
												<div
													onClick={(e) => nextClick(e, item)}
													className="next-level"
												>
													下级
												</div>
											)}
										</div>
									</List.Item>
								)}
							</VirtualList>
						</List>
					</div>
					<div className="line"></div>
					<div className="target-item">
						<div className="right-top-view">
							<span>已选:</span>
							<div onClick={() => setTargetKeys([])} className="clear-all">
								清空
							</div>
						</div>
						<List split={false}>
							<VirtualList
								data={rightList}
								height={345}
								itemHeight={48}
								itemKey="deptId"
							>
								{(item, index) => (
									<List.Item key={item.deptId}>
										<div className="right-list-item">
											<div className="left">
												<div
													className="image"
													style={{ background: linears[index % 3] }}
												>
													<span className="title">
														{item.deptName.slice(0, 2)}
													</span>
												</div>
												<div className="text-view">
													<span>{item.deptName}</span>
													<span className="sub-title">{item.companyName}</span>
												</div>
											</div>
											<span
												onClick={() => rigthDelete(item.deptId)}
												className="iconfont close"
											>
												&#xe72b;
											</span>
										</div>
									</List.Item>
								)}
							</VirtualList>
						</List>
					</div>
				</div>
				<ModalBtns onCancel={onCancel} onClick={_onOk} />
			</>
		</Modal>
	)
}
