import '@antv/x6-react-shape'
import { Graph, Markup } from '@antv/x6'
const linears = [
	'linear-gradient(0deg, #00BFA5 0%, #00D8BC 100%)',
	'linear-gradient(0deg, #9258F6 0%, #9258F6 100%)',
	'linear-gradient(0deg, #2763F0 0%, #3F79FE 100%)',
]

const bgColors = ['#A9B4CD', '#FF8800', '#3370FF', '#935AF6']
const names = ['发起人', '审批人', '抄送人', '办理人']
const node_types = ['userTask', 'toCC', 'transactor']
const addLabel = {
	markup: Markup.getForeignObjectMarkup(),
	attrs: {
		fo: {
			width: 34,
			height: 34,
			x: -17,
			y: -17,
			event: 'edge:add', // 自定义点击事件
		},
	},

	// attrs: {
	// 	bg: {
	// 		ref: 'txt',
	// 		refR: '100%',
	// 		stroke: '#3370ff',
	// 		fill: 'white',
	// 		strokeWidth: 0,
	// 		event: 'edge:add', // 自定义点击事件
	// 	},
	// 	txt: {
	// 		fill: '#3370ff',
	// 		fontSize: 20,
	// 		textAnchor: 'middle',
	// 		textVerticalAnchor: 'middle',
	// 		text: '+',
	// 	},
	// },
}
const nodeTitleAttr = {
	fill: '#ffffff',
	refX: '4%',
	refY: '12%',
	textVerticalAnchor: 'top',
	textAnchor: 'left',
	pointerEvents: 'none',
}

const nodeContentAttr = {
	fill: '#1F2329',
	refX: '4%',
	refY: '62%',
	textVerticalAnchor: 'top',
	textAnchor: 'left',
	text: '全体成员',
	pointerEvents: 'none',
	textWrap: {
		width: 180,
	},
}
const line = {
	stroke: '#C2C5CC',
	strokeWidth: 1,
	targetMarker: null,
}
const endDot = {
	id: '1',
	shape: 'rect',
	width: 200,
	height: 40,
	data: { type: 'end', nodeType: 'endEvent', id: '1', nodeName: '结束节点' },
	markup: [
		{
			tagName: 'rect',
			selector: 'body',
		},
		{
			tagName: 'circle',
			selector: 'circle',
		},
		{
			tagName: 'text',
			selector: 'endText',
		},
	],
	attrs: {
		body: {
			fill: 'transparent',
			stroke: 'transparent',
		},
		circle: {
			fill: '#A9B4CD',
			stroke: 'transparent',
			r: 5,
			refX: '50%',
			refY: 5,
		},
		endText: {
			fill: '#A9B4CD',
			text: '流程结束',
			refX: '50%',
			refY: 25,
		},
	},
}
const data = {
	nodes: [],
	edges: [],
}
data.nodes.push(getStartNode('0', 0))
data.nodes.push(endDot)
data.edges.push(
	...[
		{
			source: '0',
			target: '1',
			attrs: {
				line: line,
			},
			label: { ...addLabel, position: { distance: 25 } },
		},
	]
)
function getDot(id, type, nodeType, linkAdd) {
	return {
		id,
		shape: 'rect',
		width: 200,
		height: 1,
		data: { type, nodeType, linkAdd, id },
		zIndex: 50,
		markup: [
			{
				tagName: 'rect',
				selector: 'body',
			},
			{
				tagName: 'circle',
				selector: 'circle',
			},
		],
		attrs: {
			body: {
				fill: '#ffffff',
				stroke: 'transparent',
			},
			circle: {
				fill: '#A9B4CD',
				stroke: 'transparent',
				r: 0,
				refX: '50%',
			},
		},
	}
}

function getConditionNode(id) {
	return {
		id,
		shape: 'rect',
		width: 200,
		height: 0,
		zIndex: 1000,
		data: {
			type: 'addCondition',
			nodeType: 'exclusiveGateway',
			id,
			nodeName: '添加条件点',
		},
		markup: [
			{
				tagName: 'rect',
				selector: 'body',
			},
			{
				tagName: 'rect',
				selector: 'content',
			},
			{
				tagName: 'text',
				selector: 'condition',
			},
		],
		attrs: {
			body: {
				fill: 'transparent',
				stroke: 'transparent',
			},
			content: {
				rx: 15,
				ry: 15,
				refX: '50%',
				x: -45,
				fill: '#ffffff',
				width: 90,
				height: 30,
				stroke: 'transparent',
			},
			condition: {
				fill: '#3370FF',
				refX: '50%',
				y: 15,
				text: '添加条件',
				event: 'condition:add', // 自定义点击事件
			},
		},
	}
}
function getLeftNode(id, power) {
	return {
		id,
		shape: 'condition-node',
		width: 200,
		height: 78,
		data: {
			type: 'condition',
			nodeType: 'condition',
			power,
		},
		// component: <ConditionNode text="条件1" />,
		// markup: [
		// 	{
		// 		tagName: 'rect',
		// 		selector: 'body',
		// 	},
		// 	{
		// 		tagName: 'text',
		// 		selector: 'text1',
		// 	},
		// 	{
		// 		tagName: 'text',
		// 		selector: 'text2',
		// 	},
		// 	{
		// 		tagName: 'rect',
		// 		selector: 'line',
		// 	},
		// 	{
		// 		tagName: 'text',
		// 		selector: 'selectTitle',
		// 	},
		// ],
		// attrs: {
		// 	body: {
		// 		fill: '#ffffff',
		// 		stroke: 'transparent',
		// 		rx: 8,
		// 		ry: 8,
		// 		event: 'open:drawer',
		// 	},
		// 	text1: {
		// 		fill: '#2EB795',
		// 		refX: '4%',
		// 		refY: '12%',
		// 		textWrap: {
		// 			width: 120,
		// 			ellipsis: true,
		// 			height: 16,
		// 		},
		// 		textVerticalAnchor: 'top',
		// 		textAnchor: 'left',
		// 		text: '条件' + power,
		// 		pointerEvents: 'none',
		// 	},
		// 	text2: {
		// 		fill: '#2EB795',
		// 		refX: '82%',
		// 		refY: '20%',
		// 		text: '优先级' + power,
		// 		pointerEvents: 'none',
		// 	},
		// 	line: {
		// 		fill: '#F6F6F7',
		// 		stroke: 'transparent',
		// 		refY: '40%',
		// 		refWidth: '100%',
		// 		height: 1,
		// 	},
		// 	selectTitle: {
		// 		fill: '#A1A5AD',
		// 		refX: '4%',
		// 		refY: '60%',
		// 		textVerticalAnchor: 'top',
		// 		textAnchor: 'left',
		// 		text: '请设置条件',
		// 		pointerEvents: 'none',
		// 		textWrap: {
		// 			width: 180,
		// 			ellipsis: true,
		// 			height: 16,
		// 		},
		// 	},
		// },
	}
}

function getStartNode(id, type) {
	return {
		id,
		shape: 'rect',
		width: 200,
		height: 78,
		label: '发起人',
		data: {
			type: 'common',
			nodeType: 'startEvent',
			id: '0',
			nodeContent: '全体成员',
			nodeName: '发起人',
		},
		markup: [
			{
				tagName: 'rect',
				selector: 'body',
			},
			{
				tagName: 'g',
				children: [
					{
						tagName: 'text',
						selector: 'label',
					},
					{
						tagName: 'rect',
						selector: 'content',
					},
					{
						tagName: 'text',
						selector: 'selectTitle',
					},
				],
			},
		],
		attrs: {
			body: {
				fill: bgColors[type],
				stroke: 'transparent',
				rx: 8,
				ry: 8,
				event: 'open:drawer',
			},
			label: nodeTitleAttr,
			content: {
				x: 4,
				refY: '45%',
				rx: 6,
				ry: 6,
				fill: '#ffffff',
				width: 192,
				height: 38,
				stroke: 'transparent',
				pointerEvents: 'none',
			},
			selectTitle: nodeContentAttr,
		},
	}
}
function getNode(id, type) {
	return {
		id,
		shape: 'common-node',
		width: 200,
		height: 78,
		label: names[type],
		data: { type: 'common', nodeType: node_types[type - 1] },
		// markup: [
		// 	{
		// 		tagName: 'rect',
		// 		selector: 'body',
		// 	},
		// 	{
		// 		tagName: 'text',
		// 		selector: 'label',
		// 	},
		// 	{
		// 		tagName: 'rect',
		// 		selector: 'content',
		// 	},
		// 	{
		// 		tagName: 'text',
		// 		selector: 'selectTitle',
		// 	},
		// 	{
		// 		tagName: 'image',
		// 		selector: 'image',
		// 	},
		// ],
		// attrs: {
		// 	body: {
		// 		fill: bgColors[type],
		// 		stroke: 'transparent',
		// 		rx: 8,
		// 		ry: 8,
		// 		event: 'open:drawer',
		// 	},
		// 	label: nodeTitleAttr,
		// 	content: {
		// 		x: 4,
		// 		refY: '45%',
		// 		rx: 6,
		// 		ry: 6,
		// 		fill: '#ffffff',
		// 		width: 192,
		// 		height: 38,
		// 		stroke: 'transparent',
		// 		pointerEvents: 'none',
		// 	},
		// 	selectTitle: {
		// 		...nodeContentAttr,
		// 		text: '请设置',
		// 	},
		// },
	}
}

const orthEdge = {
	attrs: {
		line: line,
	},
	label: { ...addLabel, position: { distance: 25 } },
	router: {
		name: 'orth',
		args: {
			// padding: 80,
		},
	},
	connector: {
		name: 'rounded',
		args: {
			radius: 4,
		},
	},
	zIndex: 100,
}
const oneSideEdge = {
	attrs: {
		line: line,
	},
	label: { ...addLabel, position: { distance: 25 } },
	router: {
		name: 'oneSide',
		args: {
			padding: { bottom: 0 },
		},
	},
	connector: {
		name: 'rounded',
		args: {
			radius: 4,
		},
	},
	zIndex: 100,
}
const getConditionDotId = (_id, nodes, edges, addId) => {
	let conditionObj = edges.filter((item) => item.source === _id)[0]
	let sourceTmp = nodes.filter((item) => item.id === conditionObj.target)[0]
	console.log(sourceTmp)
	if (
		sourceTmp.data.type === 'conditionDot' &&
		sourceTmp.data.addNodeId === addId
	) {
		return sourceTmp.id
	} else {
		return getConditionDotId(sourceTmp.id, nodes, edges, addId)
	}
}
const flatten = (list = [], transferDataSource = []) => {
	list.forEach((item) => {
		transferDataSource.push(item)
		flatten(item.children, transferDataSource)
	})
}
const centerForMaxId = (graphState, nodes) => {
	let idArr = []
	nodes.map((item) => {
		idArr.push(Number(item.id))
	})
	let maxId = Math.max(...idArr)
	graphState.positionCell(graphState.findViewByCell(maxId + '').cell, 'center')
}
const handlePathNodes = (edges, id, conditionDotId, arr) => {
	let edgesSource = edges.filter((item) => item.source === id)
	if (edgesSource.length === 1 && edgesSource[0].target === conditionDotId) {
		return
	} else {
		edgesSource.map((item) => {
			arr.push(item.target)
			handlePathNodes(edges, item.target, conditionDotId, arr)
		})
	}
}
const handleAddCondition = (nodes, nextObj, mayAdd) => {
	console.log(mayAdd, 'ddddd')
	let tmpObj = nodes.filter((item) => item.id === nextObj.target)[0]
	if (tmpObj.data.type === 'end') {
		return false
	}
	if (
		tmpObj.data.type === 'conditionDot' &&
		mayAdd.id &&
		tmpObj.data.linkAdd === mayAdd.id
	) {
		mayAdd.canSet = false
		mayAdd.id = undefined
		return true
	}
	if (tmpObj.data.type === 'conditionDot' && !mayAdd.id) {
		return false
	}
	return true
}
const findEndNode = (nodes, edges, id) => {
	let addNodeId = edges.filter((item) => item.target === id)[0].source
	let endId = getConditionDotId(id, nodes, edges, addNodeId)
	let endNode = nodes.filter((item) => item.id === endId)[0]
	return endNode
}

export {
	getDot,
	getNode,
	addLabel,
	line,
	endDot,
	orthEdge,
	names,
	getConditionNode,
	getLeftNode,
	oneSideEdge,
	getConditionDotId,
	flatten,
	node_types,
	centerForMaxId,
	handlePathNodes,
	handleAddCondition,
	data,
	linears,
	findEndNode,
}
