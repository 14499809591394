import {
	Drawer,
	Form,
	Radio,
	Input,
	Space,
	Tooltip,
	Select,
	Checkbox,
	message,
} from 'antd'
import { useState, useEffect } from 'react'
import { add_man, tip_image, member_close } from '../assets/index'
import { FormBtns } from './FormBtns'
import { definition_groupList, definition_userList } from '../api/x6'
import { DrawerEditTitle } from './DrawerEditTitle'
import { TargetModal } from './TargetModal'
import { linears } from '../App.data'
const typesOptions = [
	{ label: '设置抄送人', value: 0 },
	{ label: '操作权限', value: 1, disabled: true },
]
const { Item } = Form
const types = [
	{ label: '指定成员', value: 1 },
	{ label: '项目负责人', value: 2 },
]
const initToCCFnc = (obj = {}) => {
	if (!obj.toCCType) {
		obj.toCCType = 1
	}
	if (!obj.users) {
		obj.users = []
	}
	return obj
}
// isSequential "1": 依次 "2": 会签 "3" 或签
export function ToCCDrawer({ visible, onClose, onFinish, initialToCC }) {
	const [typeTwo, setTypeTwo] = useState(0)

	const [title, setTitle] = useState('抄送人')
	const [canEdit, setCanEdit] = useState(false)

	const [userList, setUserList] = useState([])
	const [singleModal, setSingleModal] = useState(false)
	const [targetModal, setTargetModal] = useState(false)
	// console.log(initialToCC.nodeJson)
	let tmpInit = initToCCFnc(initialToCC.nodeJson)
	// console.log(tmpInit)
	const [role, setRole] = useState(tmpInit.toCCType) // 审批人
	const [members, setMembers] = useState(tmpInit.users) // 指定成员多人
	useEffect(() => {
		definition_userList()
			.then((res) => setUserList(res.records))
			.catch(() => {})
	}, [])
	const _onFinish = (v) => {
		if (role === 1 && members.length === 0) {
			return message.info('请添加成员')
		}
		if (role === 1) {
			// nodeJson
			let memberText = members.map((item) => item.userName).join(',')
			onFinish({
				nodeJson: { ...v, users: members },
				nodeContent: memberText,
				nodeName: title,
			})
		} else if (role === 2) {
			onFinish({
				nodeJson: v,
				nodeContent: '项目负责人',
				nodeName: title,
			})
		}
	}
	const singleOk = (keys = []) => {
		setTargetModal(false)
		let tmpArr = []
		keys.map((item) => {
			let targetItem = userList.filter((v) => v.uuid === item)
			if (targetItem.length === 1) {
				tmpArr.push(targetItem[0])
			}
		})
		console.log(tmpArr)
		setMembers(tmpArr.slice(0, 25))
	}
	const memberDelete = (key) =>
		setMembers(members.filter((item) => item.uuid !== key))
	return (
		<Drawer
			width={532}
			title={
				<DrawerEditTitle
					title={title}
					canEdit={canEdit}
					onChange={(v) => setTitle(v.target.value)}
					onClick={() => setCanEdit(true)}
					onBlur={() => setCanEdit(false)}
				/>
			}
			open={visible}
			onClose={onClose}
		>
			<Form
				initialValues={tmpInit}
				className="x6-approver-form"
				layout="vertical"
				onFinish={_onFinish}
			>
				<Item label="抄送人设置">
					<Radio.Group
						onChange={(v) => setTypeTwo(v.target.value)}
						options={typesOptions}
						defaultValue={typeTwo}
						buttonStyle="solid"
						optionType="button"
					></Radio.Group>
				</Item>
				{typeTwo === 0 && (
					<>
						<div className="approver-view">
							<div className="person-view">抄送人</div>
							<div className="padding">
								<Item name="toCCType">
									<Radio.Group onChange={(v) => setRole(v.target.value)}>
										<div className="wrap">
											{types.map((item) => (
												<Radio
													disabled={item.disabled}
													style={{
														width: 135,
														marginTop: 10,
													}}
													key={item.label}
													value={item.value}
												>
													{item.label}
												</Radio>
											))}
										</div>
									</Radio.Group>
								</Item>
								<div className="line"></div>

								{role === 1 && (
									<Item label="添加成员（不能超过25人）">
										<div className="members">
											<div
												onClick={() => setTargetModal(true)}
												className="add-man"
											>
												<img className="image" src={add_man}></img>
											</div>
											{members.map((item, index) => {
												return (
													<div key={item.uuid} className="members-item">
														<div
															className="circle"
															style={{ background: linears[index % 3] }}
														>
															<span className="text">
																{item.userName.slice(-2)}
															</span>
														</div>
														<span className="title">{item.userName}</span>
														<img
															onClick={() => memberDelete(item.uuid)}
															className="close"
															src={member_close}
														></img>
													</div>
												)
											})}
										</div>
									</Item>
								)}
								{/* {role === 1 && (
									<Item
										rules={[{ required: true, message: '请选择负责人' }]}
										label="选择负责人"
									>
										<Select mode="multiple" placeholder="请选择"></Select>
									</Item>
								)} */}
							</div>
						</div>
					</>
				)}

				<FormBtns onCancel={onClose} leftText="保存" />

				{targetModal && (
					<TargetModal
						title="选择抄送人"
						visible={targetModal}
						onCancel={() => setTargetModal(false)}
						onOk={singleOk}
						oriList={userList}
						tList={members.map((item) => item.uuid)}
					></TargetModal>
				)}
			</Form>
		</Drawer>
	)
}
