import { makeAutoObservable } from 'mobx'

class State {
	constructor() {
		makeAutoObservable(this)
	}
	loading = false
	changeLoading = (value) => (this.loading = value)
}
export default new State()
