export function NavView({ navArr, onClick }) {
	return (
		<div className="nav-view">
			{navArr.map((item, index) => (
				<div
					style={{
						marginRight: 10,
						color: index + 1 !== navArr.length && '#3370FF',
					}}
					key={item.deptId}
					onClick={() => onClick(item, index)}
					className={index + 1 !== navArr.length ? 'can-tap' : undefined}
				>
					{item.deptName}
				</div>
			))}
		</div>
	)
}
