import './App.less'
import { useEffect, useState, useRef } from 'react'
import { Graph, View } from '@antv/x6'
import { DagreLayout } from '@antv/layout'
import zhCN from 'antd/lib/locale/zh_CN'
import { useSearchParams } from 'react-router-dom'
import { createRoot } from 'react-dom/client'
import {
	AddModal,
	ApproverDrawer,
	StartDrawer,
	ModalBottomBtns,
	ToCCDrawer,
	ConditionDrawer,
	ModalCommon,
	ZoomView,
	CompleteModal,
	AddLabel,
} from './components/index'
import {
	getDot,
	getNode,
	addLabel,
	orthEdge,
	names,
	getConditionNode,
	getLeftNode,
	oneSideEdge,
	getConditionDotId,
	node_types,
	centerForMaxId,
	handlePathNodes,
	handleAddCondition,
	data,
	findEndNode,
} from './App.data'
import {
	Modal,
	Drawer,
	Button,
	ConfigProvider,
	message,
	Form,
	Input,
} from 'antd'
import { definition_create, definition_read_list } from './api/x6'
import { setCookie } from './utils/cookie'
import { HRO_URL_OBJ } from './api/url'
// isSequential "1": 依次 "2": 会签 "3" 或签
// 0: 发起人 1: 审批人  2: 抄送人  3: 办理人
function App() {
	const [addModal, setAddModal] = useState(false)
	const [graphState, setGraphState] = useState()
	const [layoutState, setLayoutState] = useState()
	const [deleteModal, setDeleteModal] = useState(false)
	const [addLocation, setAddLocation] = useState({ x: 0, y: 0 })
	const [startDrawer, setStartDrawer] = useState(false) // 发起人
	const [approverDrawer, setApproverDrawer] = useState(false) // 审批人抽屉
	const [initialApprove, setInitialApprove] = useState({})
	const [nameModal, setNameModal] = useState(false)
	const [toCCDrawer, setToCCDrawer] = useState(false)
	const [initialToCC, setInitialToCC] = useState({})
	const [conditionModal, setConditionModal] = useState()
	const [initCondition, setInitCondition] = useState({})
	const [errorNum, setErrorNum] = useState(0)
	const [errorModal, setErrorModal] = useState(false)
	const [eList, setEList] = useState([])
	const addRef = useRef()
	const dataRef = useRef(data)
	const deleteIdRef = useRef()
	const deleteTypeRef = useRef()
	const nodeClickId = useRef()
	const [searchParams] = useSearchParams()
	const event_obj = useRef()
	useEffect(() => {
		window.addEventListener(
			'message',
			(event) => {
				if (event.origin === HRO_URL_OBJ[process.env.REACT_APP_SECRET_API]) {
					event_obj.current = event
				}
			},
			false
		)
		setCookie('token', searchParams.get('token'))
		const graph = new Graph({
			container: document.getElementById('container'),
			// grid: true,
			// scroller: true, // 有双层

			// connecting: {
			// 	allowBlank: true,
			// },s
			background: { color: '#f0f0f2' },
			panning: {
				enabled: true, // 画布移动
			},
			interacting: () => {
				// 节点禁止移动  标签禁止移动
				return { nodeMovable: false, edgeLabelMovable: false }
			},
			onEdgeLabelRendered: (args) => {
				const { selectors } = args
				const content = selectors.foContent
				if (content) {
					const root = createRoot(content)
					root.render(<AddLabel />)
				}
			},
		})
		setGraphState(graph)
		const dagreLayout = new DagreLayout({
			type: 'dagre',
			rankdir: 'TB',
			align: undefined,
			ranksep: 30,
			nodesep: 100,
			// controlPoints: false,
			begin: [-120, -250],
		})
		setLayoutState(dagreLayout)
		const model = dagreLayout.layout(data)
		graph.on('edge:customevent', ({ name, view, e }) => {
			if (name === 'edge:add') {
				// console.log(name, view, e)
				addRef.current = view.cell.store.data
				setAddLocation(e.originalEvent)
				setAddModal(true)
			}
		})
		graph.on('node:click', ({ node }) => {
			console.log(node)
			const data = node.store.data.data
			nodeClickId.current = data.id
			if (data.type === 'condition') {
				setConditionModal(true)
				let endNode = findEndNode(
					dataRef.current.nodes,
					dataRef.current.edges,
					nodeClickId.current
				)
				console.log(endNode, 'uuuuu')
				let powerTmp = endNode.data.linkNodes.map((_, index) => ({
					value: index + 1,
					label: '优先级' + (index + 1),
				}))
				setInitCondition({
					powerList: powerTmp,
					power: data.power,
					nodeName: data.nodeName,
					expressList: data.expressList,
				})
			} else if (data.nodeType === 'userTask') {
				// 审批
				setInitialApprove(data)
				setApproverDrawer(true)
			} else if (data.nodeType === 'toCC') {
				// 抄送
				setInitialToCC(data)
				setToCCDrawer(true)
			}
		})
		graph.on('node:customevent', ({ name, view, e }) => {
			if (name === 'condition:add') {
				console.log(view.cell)
				const id = view.cell.store.data.id
				let idArr = []
				dataRef.current.nodes.map((item) => {
					idArr.push(Number(item.id))
				})
				let maxId = Math.max(...idArr)
				// 条件点
				let _dotId = getConditionDotId(
					id,
					dataRef.current.nodes,
					dataRef.current.edges,
					id
				)
				console.log(_dotId)

				let conditionNode = dataRef.current.nodes.filter(
					(item) => item.id === _dotId
				)[0]
				conditionNode.data.linkNodes.push(maxId + 1 + '')
				let conditionLength =
					dataRef.current.edges.filter((item) => item.source === id).length + 1

				let tmpNode = getLeftNode(maxId + 1 + '', conditionLength)
				tmpNode.id = maxId + 1 + ''
				tmpNode.data.id = maxId + 1 + ''
				tmpNode.data.nodeName = '条件' + conditionLength
				tmpNode.data.nodeType = 'condition'
				dataRef.current.nodes.push(tmpNode)
				dataRef.current.edges.push({
					...orthEdge,
					label: null,
					source: id,
					target: maxId + 1 + '',
				})
				dataRef.current.edges.push({
					...oneSideEdge,
					source: maxId + 1 + '',
					target: _dotId,
				})
				const model = dagreLayout.layout(dataRef.current)
				graph.fromJSON(model)
			}
			if (name === 'open:drawer') {
				let tmpData = view.cell.store.data
				nodeClickId.current = tmpData.id
				console.log(view.cell.store.data)
				let nodeType = tmpData.data.nodeType
				if (nodeType === 'startEvent') {
					setStartDrawer(true)
				}
			}
		})

		// graph.on('node:dblclick', ({ name, view, e }) => {
		// 	console.log(name, view, e)
		// 	if (view.cell.id === '0') {
		// 		const cell = view.cell
		// 		cell.removeTool('node-editor')
		// 		cell.addTools({
		// 			name: 'node-editor',
		// 			args: {
		// 				event: e,
		// 				attrs: {
		// 					backgroundColor: '#FFF',
		// 					fontSize: 24,
		// 				},
		// 				// getText: (a1) => {
		// 				// 	console.log(a1)
		// 				// },
		// 				setText: (b1) => console.log(b1, 'seeeeee'),
		// 			},
		// 		})
		// 	}
		// })
		graph.on('node:mouseenter', ({ node }) => {
			// console.log(node)
			const id = node.store.data.id
			const type = node.store.data.data.type
			// 叉号颜色
			const isBlack = type === 'condition'
			// 排除开始和结束节点
			if (id !== '1' && id !== '0' && type !== 'addCondition') {
				node.addTools({
					name: 'button',
					args: {
						markup: [
							{
								tagName: 'rect',
								attrs: {
									width: 60,
									height: 24,
									x: -50,
									y: -12,
									stroke: 'red',
									'stroke-width': 0,
									fill: isBlack ? 'white' : 'transparent',
									cursor: 'pointer',
								},
							},
							{
								tagName: 'image',
								attrs: {
									width: 14,
									height: 14,
									x: -7,
									y: -7,
									'xlink:href': isBlack
										? require('./assets/close_gray.png')
										: require('./assets/close_white.png'),
								},
							},
						],
						x: '83%',
						y: 0,
						offset: { x: 18, y: 15 },
						onClick: ({ view }) => {
							const node = view.cell
							console.log(node)
							setDeleteModal(true)
							deleteIdRef.current = id
							deleteTypeRef.current = node.store.data.data.type
						},
					},
				})
			}
		})
		graph.on('node:mouseleave', ({ node }) => {
			// node.removeTools()
			if (node.hasTool('button')) {
				node.removeTool('button')
			}
		})
		graph.positionContent('center')
		graph.fromJSON(model)
		const id = searchParams.get('id')
		definition_read_list(id)
			.then((v) => {
				console.log(JSON.parse(v.data))
				let obj = JSON.parse(v.data)
				dataRef.current = obj.data
				//------》 处理旧数据导致的bug
				dataRef.current.nodes.map((item) => {
					if (
						item.id === '0' ||
						item.id === '1' ||
						item.shape === 'common-node' ||
						item.shape === 'condition-node'
					) {
					} else if (
						item.data.type === 'common' ||
						item.data.type === 'condition'
					) {
						delete item.attrs
						delete item.markup
						if (item.data.type === 'common') {
							item.shape = 'common-node'
						}
						if (item.data.type === 'condition') {
							item.shape = 'condition-node'
						}
					}
				})
				// 《----------
				console.log(dataRef.current.nodes)
				dataRef.current.edges.map((item) => {
					if (item.label) {
						item.label = { position: { distance: 25 }, ...addLabel }
					}
				})
				graph.fromJSON(dagreLayout.layout(dataRef.current))
			})
			.catch((err) => {
				console.log(err)
			})

		return () => {
			graph.dispose()
			window.removeEventListener('message', () => {}, false)
		}
	}, [searchParams])
	const addBtnClick = (_type) => {
		const source = addRef.current.source.cell
		const target = addRef.current.target.cell
		let idArr = []
		dataRef.current.nodes.map((item) => {
			idArr.push(Number(item.id))
		})

		let maxId = Math.max(...idArr)
		if (_type === 0 || _type === 1 || _type === 2) {
			// 审批

			dataRef.current.nodes.push(getNode(maxId + 1 + '', _type + 1))
			let tmpNode = dataRef.current.nodes.filter(
				(item) => item.id === maxId + 1 + ''
			)[0].data

			tmpNode.id = maxId + 1 + ''
			// tmpNode.nodeContent = '请设置'
			tmpNode.nodeName = names[_type + 1]
			tmpNode.nodeType = node_types[_type]

			let targetEdge = dataRef.current.edges
				.filter((item) => item.source === source)
				.filter((item) => item.target === target)[0]
			console.log(targetEdge)
			targetEdge.target = maxId + 1 + ''
			let oriTarget = dataRef.current.nodes.filter(
				(item) => item.id === target
			)[0]
			console.log(oriTarget)
			if (oriTarget.data.type === 'conditionDot') {
				targetEdge.router = {
					name: 'orth',
					args: {
						// padding: 80,
					},
				}
			}

			let edgeIndex = dataRef.current.edges.findIndex(
				(item) => item.source === source
			)
			console.log(edgeIndex)
			dataRef.current.edges.splice(edgeIndex + 1, 0, {
				...(oriTarget.data.type === 'conditionDot' ? oneSideEdge : orthEdge),
				source: maxId + 1 + '',
				target: target,
			})
			console.log(dataRef.current)
		} else if (_type === 3) {
			let isConditionDot =
				dataRef.current.nodes.filter((item) => item.id === target)[0].data
					.type === 'conditionDot'
			let sourceIndex = dataRef.current.edges.findIndex(
				(item) => item.source === source
			)
			dataRef.current.edges.splice(
				sourceIndex + 1,
				0,
				...[
					{
						...orthEdge,
						label: null,
						source: maxId + 1 + '',
						target: maxId + 2 + '',
					},
					{
						...orthEdge,
						label: null,
						source: maxId + 1 + '',
						target: maxId + 3 + '',
					},
				]
			)
			if (target === '1' || isConditionDot) {
				let targetEdge = dataRef.current.edges
					.filter((item) => item.source === source)
					.filter((item) => item.target === target)[0]

				targetEdge.target = maxId + 1 + ''
				targetEdge.router = {
					name: 'orth',
					args: {
						// padding: 80,
					},
				}

				dataRef.current.edges.splice(
					sourceIndex + 3,
					0,
					...[
						{
							...oneSideEdge,
							source: maxId + 2 + '',
							target: maxId + 4 + '',
						},
						{
							...oneSideEdge,
							source: maxId + 3 + '',
							target: maxId + 4 + '',
						},
						{
							...(isConditionDot ? oneSideEdge : orthEdge),
							source: maxId + 4 + '',
							target: target,
						},
					]
				)
			} else {
				let mayAdd = { canSet: true }
				if (
					dataRef.current.nodes.filter((item) => item.id === target)[0].data
						.type === 'addCondition'
				) {
					mayAdd.id = target
				}
				let nextObj = dataRef.current.edges.filter(
					(item) => item.source === target
				)[0]
				if (!mayAdd.id) {
					let tmpObj = dataRef.current.nodes.filter(
						(item) => item.id === nextObj.target
					)[0]
					if (tmpObj.data.type === 'addCondition') {
						mayAdd.id = nextObj.target
					}
				}
				while (handleAddCondition(dataRef.current.nodes, nextObj, mayAdd)) {
					nextObj = dataRef.current.edges.filter(
						(item) => item.source === nextObj.target
					)[0]
					if (mayAdd.canSet && !mayAdd.id) {
						let tmpObj = dataRef.current.nodes.filter(
							(item) => item.id === nextObj.target
						)[0]
						if (tmpObj.data.type === 'addCondition') {
							mayAdd.id = nextObj.target
						}
					}
				}

				let targetTmp = nextObj.target
				nextObj.target = maxId + 4 + ''
				nextObj.router = {
					name: 'oneSide',
					args: {
						padding: { bottom: 0 },
					},
				}
				let toAddCondition = dataRef.current.edges.filter(
					(item) => item.target === target
				)[0]

				toAddCondition.target = maxId + 1 + ''
				let bottomLeftIndex = dataRef.current.edges.findIndex(
					(item) => item.target === maxId + 4 + ''
				)

				dataRef.current.edges.splice(
					bottomLeftIndex + 1,
					0,
					...[
						{
							...orthEdge,
							source: maxId + 2 + '',
							target: target,
						},
						{
							...oneSideEdge,
							source: maxId + 3 + '',
							target: maxId + 4 + '',
						},
					]
				)
				let _targetNode = dataRef.current.nodes.filter(
					(item) => item.id === targetTmp
				)[0]
				dataRef.current.edges.splice(bottomLeftIndex + 3, 0, {
					...(_targetNode.data.type === 'conditionDot'
						? oneSideEdge
						: orthEdge),
					source: maxId + 4 + '',
					target: targetTmp,
				})
			}
			// 条件节点
			dataRef.current.nodes.push(getConditionNode(maxId + 1 + ''))

			console.log(dataRef.current)

			// 左
			let tmpNodeLeft = getLeftNode(maxId + 2 + '', 1)
			tmpNodeLeft.id = maxId + 2 + ''
			tmpNodeLeft.data.id = maxId + 2 + ''
			tmpNodeLeft.data.nodeName = '条件1'
			tmpNodeLeft.data.nodeType = 'condition'
			dataRef.current.nodes.push(tmpNodeLeft)

			// 右
			let tmpNodeRight = getLeftNode(maxId + 3 + '', 2)
			tmpNodeRight.id = maxId + 3 + ''
			tmpNodeRight.data.id = maxId + 3 + ''
			tmpNodeRight.data.nodeName = '条件2'
			tmpNodeRight.data.nodeType = 'condition'
			dataRef.current.nodes.push(tmpNodeRight)

			// 结束点
			let endTmp = getDot(
				maxId + 4 + '',
				'conditionDot',
				'conditionDot',
				maxId + 1 + ''
			)
			endTmp.data.linkNodes = [maxId + 2 + '', maxId + 3 + '']
			endTmp.data.addNodeId = maxId + 1 + ''
			dataRef.current.nodes.push(endTmp)
		}
		console.log(dataRef.current)
		checkErrorNum()
		const model = layoutState.layout(dataRef.current)
		graphState.fromJSON(model)
		graphState.positionCell(
			graphState.findViewByCell(maxId + 1 + '').cell,
			'center'
		)
		setAddModal(false)
	}
	const deleteOk = () => {
		console.log(deleteTypeRef.current)
		if (deleteTypeRef.current === 'common') {
			const id = deleteIdRef.current
			let nodes = dataRef.current.nodes
			let edges = dataRef.current.edges
			console.log(nodes)
			let nodeTmp = nodes.filter((item) => item.id !== id)
			dataRef.current.nodes = nodeTmp
			console.log(edges)
			let targetObj = edges.filter((item) => item.source === id)[0]
			let sourceObj = edges.filter((item) => item.target === id)[0]
			console.log(targetObj, sourceObj)
			dataRef.current.edges = edges.filter((item) => item.source !== id)

			let upIndex = dataRef.current.edges.findIndex(
				(item) => item.target === id
			)
			dataRef.current.edges.splice(upIndex, 1, {
				...(nodes.filter((item) => item.id === targetObj.target)[0].data
					.type === 'conditionDot'
					? oneSideEdge
					: orthEdge),
				source: sourceObj.source,
				target: targetObj.target,
			})
		} else if (deleteTypeRef.current === 'condition') {
			console.log(deleteIdRef.current)
			// 添加条件按钮的id
			let topNodeId = dataRef.current.edges.filter(
				(item) => item.target === deleteIdRef.current
			)[0].source
			// 条件点
			let conditionDotId = getConditionDotId(
				deleteIdRef.current,
				dataRef.current.nodes,
				dataRef.current.edges,
				topNodeId
			)
			let conditionDotNode = dataRef.current.nodes.filter(
				(item) => item.id === conditionDotId
			)[0]
			conditionDotNode.data.linkNodes = conditionDotNode.data.linkNodes.filter(
				(item) => item !== deleteIdRef.current
			)
			let toDotNumbers = dataRef.current.edges.filter(
				(item) => item.target === conditionDotId
			).length
			let deleteId = deleteIdRef.current
			let shouldDelNodes = []
			handlePathNodes(
				dataRef.current.edges,
				deleteId,
				conditionDotId,
				shouldDelNodes
			)
			console.log(shouldDelNodes)
			shouldDelNodes.map((item) => {
				dataRef.current.nodes = dataRef.current.nodes.filter(
					(v) => v.id !== item
				)
				dataRef.current.edges = dataRef.current.edges.filter(
					(v) => v.source !== item
				)
			})
			dataRef.current.nodes = dataRef.current.nodes.filter(
				(item) => item.id !== deleteId
			)
			dataRef.current.edges = dataRef.current.edges
				.filter((item) => item.target !== deleteId)
				.filter((item) => item.source !== deleteId)
			console.log(dataRef.current)
			// 优先级重排 ------>
			let tmpNodes = []
			dataRef.current.edges
				.filter((item) => item.source === topNodeId)
				.map((item) => {
					tmpNodes.push(
						dataRef.current.nodes.filter((v) => v.id === item.target)[0]
					)
				})
			tmpNodes.sort((a, b) => a.power < b.power)
			tmpNodes.map((item, index) => {
				item.data.power = index + 1
			})
			// <-----end
			if (toDotNumbers === 2) {
				// 另一个条件id
				let lastId = dataRef.current.edges.filter(
					(item) => item.source === topNodeId
				)[0].target
				// 添加条件的上级线
				let upCondition = dataRef.current.edges.filter(
					(item) => item.target === topNodeId
				)[0]
				let lastArrowSourceId = dataRef.current.edges.filter(
					(item) => item.target === conditionDotId
				)[0].source
				// 条件结束点指向
				let endEdge = dataRef.current.edges.filter(
					(item) => item.source === conditionDotId
				)[0]
				console.log(dataRef.current.edges, lastId, topNodeId)
				// 另一条件指向节点
				let aditionTarget = dataRef.current.edges.filter(
					(item) => item.source === lastId
				)[0].target
				let aditionSource = dataRef.current.edges.filter(
					(item) => item.source === lastId
				)[0].source
				// 删除另一条件上下连线、末尾线
				dataRef.current.edges = dataRef.current.edges
					.filter((item) => item.target !== lastId)
					.filter((item) => item.source !== lastId)
					.filter((item) => item.source !== lastArrowSourceId)
				// 删除添加节点 // 删除条件结束点 // 删除另一条件点
				dataRef.current.nodes = dataRef.current.nodes
					.filter((item) => item.id !== topNodeId)
					.filter((item) => item.id !== conditionDotId)
					.filter((item) => item.id !== aditionSource)
				if (aditionTarget === conditionDotId) {
					dataRef.current.edges = dataRef.current.edges.filter(
						(item) =>
							!(
								item.target === endEdge.target && item.source === conditionDotId
							)
					)
					upCondition.target = endEdge.target
				} else {
					upCondition.target = aditionTarget
					endEdge.source = lastArrowSourceId
				}
				let tmpType = dataRef.current.nodes.filter(
					(item) => item.id === upCondition.target
				)[0].data.type
				upCondition.zIndex = 100
				if (tmpType === 'conditionDot') {
					upCondition.router = {
						name: 'oneSide',
						args: {
							padding: { bottom: 0 },
						},
					}
				} else {
					upCondition.router = {
						name: 'orth',
						args: {
							// padding: 80,
						},
					}
				}
			}
		}
		console.log(dataRef.current)
		checkErrorNum()
		const model = layoutState.layout(dataRef.current)
		graphState.fromJSON(model)
		centerForMaxId(graphState, dataRef.current.nodes)
		setDeleteModal(false)
	}
	const startFinish = (v) => {
		setStartDrawer(false)
		let clickNode = dataRef.current.nodes.filter(
			(item) => item.id === nodeClickId.current
		)[0]
		clickNode.label = v.title
		const model = layoutState.layout(dataRef.current)
		graphState.fromJSON(model)
	}

	const approverFinish = (v) => {
		setApproverDrawer(false)
		let clickNode = dataRef.current.nodes.filter(
			(item) => item.id === nodeClickId.current
		)[0]
		console.log(clickNode)
		let tmpNode = clickNode.data
		tmpNode.nodeContent = v.nodeContent
		tmpNode.nodeName = v.nodeName
		tmpNode.nodeJson = v.nodeJson
		checkErrorNum()
		const model = layoutState.layout(dataRef.current)
		graphState.fromJSON(model)
		console.log(dataRef.current)
	}

	const nameFinish = (v) => {
		definition_create({
			category: searchParams.get('category'),
			name: v.saveName,
			processId: searchParams.get('id'),
			data: dataRef.current,
		})
			.then((res) => {
				message.success('保存成功')
				setNameModal(false)
				const id = searchParams.get('id')
				let tmpStr = window.location.href.split(id).join(res)

				if (event_obj.current) {
					console.log(event_obj.current)
					event_obj.current.source.postMessage(
						'approval_set_finish',
						event_obj.current.origin
					)
				}
				let t = setTimeout(() => {
					if (t) {
						clearTimeout(t)
						t = null
					}
					window.location = tmpStr
				}, 1000)
			})
			.catch(() => {})
	}
	const toCCFinish = (v) => {
		setToCCDrawer(false)
		approverFinish(v)
	}
	const conditionFinish = (v) => {
		setConditionModal(false)
		if (initCondition.power !== v.power) {
			// 切换优先级
			let endNode = findEndNode(
				dataRef.current.nodes,
				dataRef.current.edges,
				nodeClickId.current
			)
			let linkNodes = endNode.data.linkNodes
			let changeId = ''
			linkNodes.map((item) => {
				let targetPower = dataRef.current.nodes.filter(
					(_item) => _item.id === item
				)[0].data.power
				if (targetPower === v.power) {
					changeId = item
				}
			})
			let changeTmp = dataRef.current.nodes.filter(
				(item) => item.id === changeId
			)[0]
			changeTmp.data.power = initCondition.power

			let clickTmp = dataRef.current.nodes.filter(
				(item) => item.id === nodeClickId.current
			)[0]
			clickTmp.data.power = v.power
		}
		let clickNode = dataRef.current.nodes.filter(
			(item) => item.id === nodeClickId.current
		)[0]
		let tmpNode = clickNode.data
		tmpNode.nodeContent = v.conditionText
		tmpNode.nodeName = v.nodeName
		tmpNode.expressList = v.expressList
		tmpNode.canSave = v.canSave
		checkErrorNum()
		const model = layoutState.layout(dataRef.current)
		graphState.fromJSON(model)
	}
	const checkErrorNum = () => {
		let tmpNum = 0
		let errorList = []
		dataRef.current.nodes.map((item) => {
			const { nodeType, nodeContent, canSave, id } = item.data
			if (nodeType === 'userTask' || nodeType === 'toCC') {
				if (!nodeContent) {
					tmpNum += 1
					if (nodeType === 'userTask') {
						errorList.push({ id, content: '未设置审批人' })
					} else if (nodeType === 'toCC') {
						errorList.push({ id, content: '未设置抄送人' })
					}
				}
			} else if (nodeType === 'condition') {
				if (!canSave) {
					tmpNum += 1
					errorList.push({ id, content: '未设置条件' })
				}
			}
		})
		setErrorNum(tmpNum)
		setEList(errorList)
	}
	const publishVerify = () => {
		if (errorNum > 0) {
			setErrorModal(true)
		} else {
			setNameModal(true)
		}
	}
	const completeOk = () => {
		setErrorModal(false)
	}
	return (
		<ConfigProvider locale={zhCN}>
			<div className="App">
				<div className="top-view">
					{errorNum > 0 && (
						<span className="error-num">{errorNum + '项不完善'}</span>
					)}
					<Button onClick={publishVerify} className="save" type="primary">
						发布
					</Button>
				</div>
				<div id="container" className="graph">
					<AddModal
						btnClick={addBtnClick}
						visible={addModal}
						onCancel={() => setAddModal(false)}
						location={addLocation}
					/>
					<StartDrawer
						visible={startDrawer}
						onClose={() => setStartDrawer(false)}
						onFinish={startFinish}
					/>
					{approverDrawer && (
						<ApproverDrawer
							initialApprove={initialApprove}
							visible={approverDrawer}
							onClose={() => setApproverDrawer(false)}
							onFinish={approverFinish}
						/>
					)}
					{toCCDrawer && (
						<ToCCDrawer
							initialToCC={initialToCC}
							visible={toCCDrawer}
							onClose={() => setToCCDrawer(false)}
							onFinish={toCCFinish}
						/>
					)}
					{conditionModal && (
						<ConditionDrawer
							initialCondition={initCondition}
							visible={conditionModal}
							onClose={() => setConditionModal(false)}
							onFinish={conditionFinish}
							flowCode={searchParams.get('category')}
						/>
					)}
					<Modal
						footer={null}
						onCancel={() => setNameModal(false)}
						centered
						open={nameModal}
						title="发布"
						destroyOnClose={true}
					>
						<Form onFinish={nameFinish} layout="vertical">
							<Form.Item
								rules={[{ required: true, message: '请输入名称' }]}
								name="saveName"
								label="审批流名称"
							>
								<Input placeholder="请输入名称"></Input>
							</Form.Item>
							<ModalBottomBtns onCancel={() => setNameModal(false)} />
						</Form>
					</Modal>

					<ModalCommon
						visible={deleteModal}
						onOk={deleteOk}
						onCancel={() => setDeleteModal(false)}
						tip1="确认要删除当前审批配置吗？"
						tip2="确认删除后，当前审批配置信息将被清空。"
					></ModalCommon>
					<CompleteModal
						onCancel={() => setErrorModal(false)}
						visible={errorModal}
						onOk={completeOk}
						list={eList}
					></CompleteModal>
				</div>
				<ZoomView graphState={graphState} />
			</div>
		</ConfigProvider>
	)
}
export default App
