import { Post, Get } from './request'

export function definition_list(params) {
	return Get('/hro-flowable/flowable/definition/list', params)
}
// 用户
export function definition_userList() {
	return Post('/hro-flowable/rest/hro/find/users')
}
// 角色
export function definition_groupList() {
	return Post('/hro-flowable/rest/hro/find/roles')
}
// 部门
export function definition_department() {
	return Post('/hro-flowable/rest/hro/find/dep')
}
// 提交
export function definition_create(params) {
	return Post('/hro-flowable/flowable/definition/creat', params)
}

export function definition_read_list(id) {
	return Get('/hro-flowable/flowable/definition/readProcessImage/' + id)
}

// > < =
export function queryExpression() {
	return Get('/hro-flowable/flowable/definition/queryExpression')
}
// 配置
export function queryProjectSetting(params) {
	return Get('/hro-flowable/flowable/definition/queryProjectSetting', params)
}
// 组织结构 ///hro-flowable/rest/hro/find/dep
export function findDep(params) {
	return Post('/hro-flowable/rest/hro/find/dep', params)
}
// 条件选择获取
export function queryConditionByCode(params) {
	return Post('/hro-flowable/rest/hro/find/queryConditionByCode', params)
}
