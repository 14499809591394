/*
 发起人（人员/角色/部门）    init
 项目   projectName
 结算类型 type 
 金额  money
  结转金额  amountCarriedForward
  转为收入  convertToIncome
  代发企业    companyName
  员工数  num
  导入类型  typeName
  参保方案  schemeName
 */
const handleList = (arr, options) => {
	if (!arr || arr.length === 0) {
		return [
			{
				key: new Date().getTime(),
				title: '条件1',
				list: [{ ...options[0], checked: true }],
			},
		]
	}
}
const settlementOptions = [
	{ label: '含薪资', value: '01' },
	{ label: '含社保/公积金', value: '02' },
	{ label: '服务费', value: '03' },
	{ label: '其他', value: '04' },
]
export { handleList, settlementOptions }
