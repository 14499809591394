import { Form, Select } from 'antd'
const { Item } = Form
export function TargetItem({ userList, isShow, name }) {
	return (
		isShow && (
			<Item
				rules={[{ required: true, message: '请指定成员' }]}
				label="指定成员"
				name={name}
			>
				<Select placeholder="请选择">
					{userList.map((item) => {
						return (
							<Select.Option key={item.uuid} value={item.uuid}>
								{item.userName}
							</Select.Option>
						)
					})}
				</Select>
			</Item>
		)
	)
}
