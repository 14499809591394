import { Drawer, Form, Radio, Input, Button } from 'antd'
import { useState } from 'react'
import { edit_gray, close_drawer } from '../assets/index'
import { FormBtns } from './FormBtns'
import { DrawerEditTitle } from './DrawerEditTitle'
const { Item } = Form
const settingOptions = [
	{ label: '设置审批人', value: 0 },
	{ label: '表单权限', value: 1 },
]
const wayOptions = [
	{ label: '组织内成员', value: 0 },
	{ label: '组织外成员', value: 1, disabled: true },
]
export function StartDrawer({
	visible,
	onClose,
	onFinish,
	submitText,
	openStart,
}) {
	const [setting, setSetting] = useState(0)
	const [title, setTitle] = useState('发起人')
	const [canEdit, setCanEdit] = useState(false)
	const _onFinish = () => {
		onFinish({ title })
	}
	return (
		<Drawer
			width={532}
			closeIcon={
				<div>
					<img src={close_drawer} className="start-close"></img>
				</div>
			}
			title={
				<DrawerEditTitle
					onChange={(v) => setTitle(v.target.value)}
					onClick={() => setCanEdit(true)}
					onBlur={() => setCanEdit(false)}
					title={title}
					canEdit={canEdit}
				/>
			}
			open={visible}
			onClose={onClose}
		>
			<Form
				className="x6-start-form"
				initialValues={{ a: 0, b: 0 }}
				layout="vertical"
				onFinish={_onFinish}
			>
				<Item label="审批人设置">
					<Radio.Group
						defaultValue={0}
						onChange={(v) => setSetting(v.target.value)}
						optionType="button"
						options={settingOptions}
						buttonStyle="solid"
					></Radio.Group>
				</Item>
				{setting === 0 ? (
					<>
						<Item name="b" label="多人审批时采用的审批方式">
							<Radio.Group options={wayOptions}></Radio.Group>
						</Item>
						{/* <Item name="c" label="谁可以提交">
							<div className="who-submit" onClick={openStart}>
								<span>{submitText}</span>
							</div>
						</Item> */}
						<Item label="提示">
							<div className="tip">
								<div className="dot"></div>
								<span>抄送人数最多支持100以内</span>
							</div>
						</Item>
					</>
				) : null}
				<FormBtns onCancel={onClose} leftText="保存" />
			</Form>
		</Drawer>
	)
}
