import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { observer } from 'mobx-react'
import { Spin, ConfigProvider } from 'antd'
import { NoPermission } from './403'
import { NoFound } from './404'
import App from './App'
import State from './MyRouter.state'
function MyRouter() {
	return (
		<ConfigProvider
			theme={{
				token: {
					colorPrimary: '#3370ff',
					colorPrimaryHover: '#3370ff', // 输入框活跃时等
					borderRadius: 4,
				},
			}}
		>
			<Spin spinning={State.loading}>
				<Routes>
					<Route path="/" element={<App />}></Route>
					<Route path="/403" element={<NoPermission />}></Route>
					<Route path="*" element={<NoFound />}></Route>
				</Routes>
			</Spin>
		</ConfigProvider>
	)
}
export default observer(MyRouter)
