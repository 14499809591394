const BASE_URL_OBJ = {
	// development: 'http://192.168.124.88:9090',
	development: 'https://flow-api.shiludev.com',
	dev: 'http://192.168.124.88:9090', // 开发机
	production: 'https://flow-api.shilutech.com',
	pre: 'https://flow-api.shiludev.com',
	'pre-test': 'https://flow-api.shiludev.com',
}
const HRO_URL_OBJ = {
	development: 'http://localhost:8000',
	dev: 'http://www.hro.com', // 开发机
	production: 'https://hro.shilutech.com',
	pre: 'https://hro.shiludev.com',
	'pre-test': 'https://hro.shiludev.com',
}
export { BASE_URL_OBJ, HRO_URL_OBJ }
