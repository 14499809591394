import State from '../MyRouter.state'
let loadingNum = 0
function addLoading() {
	if (loadingNum <= 0) {
		State.changeLoading(true)
	}

	loadingNum++
}
function subLoading() {
	if (loadingNum <= 1) {
		State.changeLoading(false)
	}
	loadingNum--
}
export { addLoading, subLoading }
