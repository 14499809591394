import { not_found } from './assets/index'
import { Button } from 'antd'
import { useNavigate } from 'react-router-dom'
export function NoFound() {
	const navigate = useNavigate()
	return (
		<div className="not-found">
			<img className="image" src={not_found}></img>
			<span className="tip">你访问的页面出错了</span>
			<span>请点击返回或刷新页面</span>
			<Button className="btn" onClick={() => navigate(-1)} type="primary">
				返回上一页
			</Button>
		</div>
	)
}
