export function TwoSelectView({ selectIndex, leftClick, rightClick }) {
	return (
		<div className="select-index-div">
			<div
				className={`left ${selectIndex === 0 ? 'active-style' : undefined}`}
				onClick={leftClick}
			>
				组织架构
			</div>
			<div
				className={`right ${selectIndex === 1 ? 'active-style' : undefined}`}
				onClick={rightClick}
			>
				角色列表
			</div>
		</div>
	)
}
