import { Drawer, Form, Tooltip, Select, Checkbox, Button, Modal } from 'antd'
import { useState, useEffect, useRef } from 'react'
import {
	queryExpression,
	queryProjectSetting,
	queryConditionByCode,
} from '../api/x6'
import { FormBtns } from './FormBtns'
import { DrawerEditTitle } from './DrawerEditTitle'
import { handleList, settlementOptions } from './condition.data'
import { TwoTargetModal } from './TwoTargetModal'
import { SelectView } from './SelectView'
const { Item } = Form
const conditionTip = `优先匹配高优先级的条件，“优先级1”为最高优先级，若满足
条件则进入该分支若不满足则匹配下一个优先级的条件
`

export function ConditionDrawer({
	visible,
	onClose,
	onFinish,
	initialCondition,
	flowCode,
}) {
	const { power, powerList, nodeName } = initialCondition
	const [title, setTitle] = useState(nodeName)
	const [canEdit, setCanEdit] = useState(false)
	const [addModal, setAddModal] = useState(false)
	const [list, setList] = useState([])
	const [checkValue, setCheckValue] = useState([])
	const [twoModal, setTwoModal] = useState(false)
	const [tList, setTList] = useState([])
	const [boxOptions, setBoxOptions] = useState([])
	const [expressionOptions, setExpressionOptions] = useState([])
	const [projectOptions, setProjectOptions] = useState([])
	const [companyOptions, setCompanyOptions] = useState([])
	const [typeOptions, setTypeOptions] = useState([])
	const [schemeOptions, setSchemeOptions] = useState([])

	const [currentPower, setCurrentPower] = useState(power)
	const checkedKeyRef = useRef() // 组key
	const checkedIdRef = useRef() // 组内单id
	useEffect(() => {
		queryProjectSetting({ flowCode })
			.then((res) => {
				console.log(res)
				if (initialCondition.expressList) {
					setList(initialCondition.expressList)
				} else {
					setList(handleList([], res))
				}
				setBoxOptions(
					res.map((item) => {
						return { ...item, value: item.id, label: item.displayName }
					})
				)
				let tmpArr = res.map((item) => item.code)
				tmpArr.map((item) => {
					if (item === 'projectName') {
						queryConditionByCode({ code: item })
							.then((_res) => setProjectOptions(_res))
							.catch(() => {})
					} else if (item === 'companyName') {
						queryConditionByCode({ code: item })
							.then((_res) => setCompanyOptions(_res))
							.catch(() => {})
					} else if (item === 'typeName') {
						queryConditionByCode({ code: item })
							.then((_res) => setTypeOptions(_res))
							.catch(() => {})
					} else if (item === 'schemeName') {
						queryConditionByCode({ code: item })
							.then((_res) => setSchemeOptions(_res))
							.catch(() => {})
					}
				})
				if (
					tmpArr.includes('money') ||
					tmpArr.includes('amountCarriedForward') ||
					tmpArr.includes('convertToIncome') ||
					tmpArr.includes('num')
				) {
					queryExpression()
						.then((res) => {
							res.map((item) => {
								item.label = item.name
								item.value = item.id
							})
							setExpressionOptions(res)
						})
						.catch(() => {})
				}
			})
			.catch(() => {})
	}, [flowCode, initialCondition])
	const _onFinish = (v) => {
		let obj = {}
		obj.power = currentPower
		obj.nodeName = title
		obj.expressList = list
		console.log(obj)
		let conditionTextArr = []
		let canSave = true
		list.map((item) => {
			let itemTextArr = []
			item.list.map((_item, _index) => {
				if (_item.code === 'init') {
					if (_item.targetList && _item.targetList.length > 0) {
						let init_text = _item.displayName + ':' + _item.sendText
						itemTextArr.push(init_text)
					} else {
						canSave = false
					}
				} else if (
					_item.code === 'projectName' ||
					_item.code === 'companyName' ||
					_item.code === 'typeName' ||
					_item.code === 'schemeName'
				) {
					let optionText = ''
					if (_item.code === 'projectName') {
						let tmpTargetArr = projectOptions.filter(
							(oItem) => oItem.id === _item.iValue
						)
						if (tmpTargetArr.length > 0) {
							optionText = tmpTargetArr[0].name
						}
					} else if (_item.code === 'companyName') {
						let tmpTargetArr = companyOptions.filter(
							(oItem) => oItem.id === _item.iValue
						)
						if (tmpTargetArr.length > 0) {
							optionText = tmpTargetArr[0].name
						}
					} else if (_item.code === 'typeName') {
						let tmpTargetArr = typeOptions.filter(
							(oItem) => oItem.id === _item.iValue
						)
						if (tmpTargetArr.length > 0) {
							optionText = tmpTargetArr[0].name
						}
					} else if (_item.code === 'schemeName') {
						let tmpTargetArr = schemeOptions.filter(
							(oItem) => oItem.id === _item.iValue
						)
						if (tmpTargetArr.length > 0) {
							optionText = tmpTargetArr[0].name
						}
					}
					if (optionText) {
						let option_text = _item.displayName + ':' + optionText
						itemTextArr.push(option_text)
					} else {
						canSave = false
					}
				} else if (
					_item.code === 'money' ||
					_item.code === 'amountCarriedForward' ||
					_item.code === 'convertToIncome' ||
					_item.code === 'num'
				) {
					if (_item.sValue && _item.iValue) {
						let moneyExpress = expressionOptions.filter(
							(eItem) => eItem.value === _item.sValue
						)[0].label
						let money_text =
							_item.displayName + ':' + moneyExpress + _item.iValue
						itemTextArr.push(money_text)
					} else {
						canSave = false
					}
				} else if (_item.code === 'type') {
					if (_item.targetList && _item.targetList.length > 0) {
						let settlementTypeText = _item.targetList
							.map(
								(stItem) =>
									_item.settlementOptions.filter(
										(_stItem) => _stItem.value === stItem
									)[0].label
							)
							.join('、')
						let settle_text = _item.displayName + ':' + settlementTypeText
						itemTextArr.push(settle_text)
					} else {
						canSave = false
					}
				}
			})
			conditionTextArr.push(itemTextArr.join('且'))
		})
		obj.conditionText = conditionTextArr.filter((_v) => _v).join('或')
		obj.canSave = canSave
		onFinish(obj)
	}
	const addFnc = (key) => {
		checkedKeyRef.current = key
		let targetList = list.filter((item) => item.key === key)[0].list
		let tmpArr = []
		targetList
			.filter((item) => item.checked)
			.map((item) => {
				tmpArr.push(item.id)
			})
		setCheckValue(tmpArr)
		setAddModal(true)
	}
	const boxOnChange = (v) => {
		console.log(v)
		setCheckValue(v)
	}
	const modalOk = () => {
		let targetList = list.filter(
			(item) => item.key === checkedKeyRef.current
		)[0].list
		checkValue.map((item) => {
			let hasTarget = targetList.filter((v) => v.id === item)
			if (hasTarget.length === 0) {
				let tmpObj = boxOptions.filter((v) => v.id === item)[0]
				targetList.push({
					...tmpObj,
					checked: true,
				})
			}
		})
		let tmpArr = []
		checkValue.map((item) => {
			tmpArr.push(targetList.filter((v) => v.id === item)[0])
		})
		console.log(tmpArr)
		list.filter((item) => item.key === checkedKeyRef.current)[0].list = tmpArr
		setList([...list])
		setAddModal(false)
	}
	const subDelete = (key, id) => {
		let targetList = list.filter((item) => item.key === key)[0].list
		list.filter((item) => item.key === key)[0].list = targetList.filter(
			(item) => item.id !== id
		)
		setList([...list])
	}
	const addGroup = () => {
		list.push({
			key: new Date().getTime(),
			title: '条件' + (list.length + 1),
			list: [{ ...boxOptions[0], checked: true }],
		})
		setList([...list])
	}
	const groupDelete = (key) => {
		let lastList = list.filter((item) => item.key !== key)
		lastList.map((item, index) => {
			item.title = '条件' + (index + 1)
		})
		setList([...lastList])
	}
	const twoModalOk = (twoModalKeys, twoModalText) => {
		setTwoModal(false)
		let tmpList = list.filter((item) => item.key === checkedKeyRef.current)[0]
			.list
		let targetObj = tmpList.filter(
			(item) => item.id === checkedIdRef.current
		)[0]
		targetObj.sendText = twoModalText
		targetObj.targetList = twoModalKeys
		setList([...list])
	}
	const startOpen = (_key, _id) => {
		setTwoModal(true)
		checkedKeyRef.current = _key
		checkedIdRef.current = _id
		let tmpList = list.filter((item) => item.key === checkedKeyRef.current)[0]
			.list
		let targetObj = tmpList.filter(
			(item) => item.id === checkedIdRef.current
		)[0]
		// 设置已选的keys
		setTList(targetObj.targetList)
	}
	const sChange = (v, key, id) => {
		let targetList = list.filter((item) => item.key === key)[0].list
		targetList.filter((item) => item.id === id)[0].sValue = v
		setList([...list])
	}
	const iChange = (v, key, id) => {
		let targetList = list.filter((item) => item.key === key)[0].list
		targetList.filter((item) => item.id === id)[0].iValue = v
		setList([...list])
	}
	const optionsChange = (v, key, id) => {
		let targetList = list.filter((item) => item.key === key)[0].list
		targetList.filter((item) => item.id === id)[0].iValue = v
		setList([...list])
	}
	const settlementChange = (v, key, id) => {
		let targetList = list.filter((item) => item.key === key)[0].list
		let targetObj = targetList.filter((item) => item.id === id)[0]
		targetObj.targetList = v
		let settleTmpOptions = JSON.parse(JSON.stringify(settlementOptions))
		if (v.includes('01') || v.includes('02')) {
			settleTmpOptions[2].disabled = true
			settleTmpOptions[3].disabled = true
		} else {
			settleTmpOptions[2].disabled = false
			settleTmpOptions[3].disabled = false
		}
		if (v.includes('03') || v.includes('04')) {
			settleTmpOptions[0].disabled = true
			settleTmpOptions[1].disabled = true
		} else {
			settleTmpOptions[0].disabled = false
			settleTmpOptions[1].disabled = false
		}
		targetObj.settlementOptions = settleTmpOptions
		setList([...list])
	}
	console.log(list, 'kkkk')
	return (
		<Drawer
			width={532}
			className="condition-drawer"
			bodyStyle={{ padding: 0 }}
			open={visible}
			onClose={onClose}
		>
			<div>
				<div className="header">
					<DrawerEditTitle
						title={title}
						canEdit={canEdit}
						onChange={(v) => setTitle(v.target.value)}
						onClick={() => setCanEdit(true)}
						onBlur={() => setCanEdit(false)}
					/>
					<div className="right">
						<Tooltip title={conditionTip}>
							<span className="iconfont image">&#xe745;</span>
						</Tooltip>
						<Select
							value={currentPower}
							options={powerList}
							placeholder="请选择"
							onChange={(v) => setCurrentPower(v)}
						></Select>
					</div>
				</div>
				<div className="body">
					<div className="tip-view">
						<span className="iconfont tip-left">&#xe74f;</span>
						<div className="tip-right">当审批单满足以下条件时进入当前分支</div>
					</div>
					{list.map((item, index) => {
						return (
							<div key={item.key}>
								<div className="item">
									<div className="item-top">
										<span className="item-title">{item.title}</span>
										<span
											onClick={() => groupDelete(item.key)}
											className="iconfont delete"
										>
											&#xe698;
										</span>
									</div>
									{item.list.map((v, i) => {
										return v.code === 'type' ? (
											<div key={v.id} className="settle-view">
												<div className="settle-title-view">
													<span>{v.displayName}</span>
													<span
														onClick={() => subDelete(item.key, v.id)}
														style={{ marginLeft: 12 }}
														className="iconfont delete"
													>
														&#xe698;
													</span>
												</div>
												<Checkbox.Group
													value={v.targetList}
													onChange={(_v) =>
														settlementChange(_v, item.key, v.id)
													}
													options={v.settlementOptions || settlementOptions}
												></Checkbox.Group>
											</div>
										) : (
											<div key={v.id}>
												<div className="sub-item">
													<div className="sub-left">{v.displayName}</div>

													<div className="sub-mid">
														{(v.code === 'money' ||
															v.code === 'amountCarriedForward' ||
															v.code === 'convertToIncome' ||
															v.code === 'num') && (
															<SelectView
																sValue={v.sValue}
																iValue={v.iValue}
																expressionOptions={expressionOptions}
																sChange={sChange}
																iChange={iChange}
																mainKey={item.key}
																id={v.id}
															/>
														)}
														{(v.code === 'projectName' ||
															v.code === 'companyName' ||
															v.code === 'typeName' ||
															v.code === 'schemeName') && (
															<Select
																className="select-options"
																options={
																	(v.code === 'projectName' &&
																		projectOptions) ||
																	(v.code === 'companyName' &&
																		companyOptions) ||
																	(v.code === 'typeName' && typeOptions) ||
																	(v.code === 'schemeName' && schemeOptions)
																}
																allowClear
																placeholder="请选择"
																value={v.iValue}
																fieldNames={{ label: 'name', value: 'id' }}
																onChange={(_v) =>
																	optionsChange(_v, item.key, v.id)
																}
															></Select>
														)}
														{v.code === 'init' && (
															<div
																onClick={() => startOpen(item.key, v.id)}
																className="start-view"
															>
																{v.sendText ? (
																	<span className="place-text">
																		{v.sendText}
																	</span>
																) : (
																	<span className="place-default">
																		请选择提交人的角色/部门
																	</span>
																)}
															</div>
														)}
													</div>

													<span
														onClick={() => subDelete(item.key, v.id)}
														style={{ marginLeft: 12 }}
														className="iconfont delete"
													>
														&#xe698;
													</span>
												</div>
												{item.list.length !== i + 1 && (
													<div className="and">且</div>
												)}
											</div>
										)
									})}
									<div onClick={() => addFnc(item.key)} className="add">
										<span className="iconfont">&#xe6fa;</span>添加条件
									</div>
								</div>
								{list.length !== index + 1 && <div className="or">或满足</div>}
							</div>
						)
					})}
					{list.length <= 2 && boxOptions.length > 0 && (
						<Button onClick={addGroup} className="add-group">
							<span className="iconfont">&#xe6fa;</span>添加条件组
						</Button>
					)}
					<Form layout="vertical" onFinish={_onFinish}>
						<FormBtns onCancel={onClose} leftText="保存" />
					</Form>
				</div>

				<Modal
					open={addModal}
					onCancel={() => setAddModal(false)}
					destroyOnClose
					title="选择条件"
					className="condition-modal"
					onOk={modalOk}
				>
					<div>
						<div style={{ marginBottom: 8 }}>
							请选择用来区分审批流程的条件字段
						</div>
						<Checkbox.Group
							defaultValue={checkValue}
							onChange={boxOnChange}
							options={boxOptions}
						></Checkbox.Group>
					</div>
				</Modal>
				{twoModal && (
					<TwoTargetModal
						title="选择发起人"
						visible={twoModal}
						onCancel={() => setTwoModal(false)}
						onOk={twoModalOk}
						tList={tList}
					></TwoTargetModal>
				)}
			</div>
		</Drawer>
	)
}
