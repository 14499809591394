import { Transfer, Modal, List, Input, Button } from 'antd'
import React, { useEffect, useState } from 'react'
import VirtualList from 'rc-virtual-list'
import { select_empty, select_yes } from '../assets/index'
import { linears } from '../App.data'
import { ModalBtns } from './ModalBtns'
export function TargetModal({
	visible,
	onCancel,
	onOk,
	title,
	tList = [],
	oriList,
}) {
	const [targetKeys, setTargetKeys] = useState(tList)
	const [selectAll, setSelectAll] = useState(false)
	const [leftArr, setLeftArr] = useState(oriList)
	const _onOk = () => {
		onOk(targetKeys)
	}
	const leftItemClick = (item) => {
		let isLeftHas = targetKeys.includes(item.uuid)
		console.log(isLeftHas, '------')
		if (isLeftHas) {
			setTargetKeys(targetKeys.filter((v) => v !== item.uuid))
		} else {
			setTargetKeys([...targetKeys, item.uuid])
		}
	}
	const rightList = targetKeys.map(
		(item) => oriList.filter((v) => v.uuid === item)[0]
	)
	const rigthDelete = (id) => {
		setTargetKeys(targetKeys.filter((v) => v !== id))
		selectAll && setSelectAll(false)
	}
	const selectAllClick = () => {
		if (selectAll) {
			setSelectAll(false)
			setTargetKeys([])
		} else {
			setSelectAll(true)
			setTargetKeys(leftArr.map((item) => item.uuid))
		}
	}
	const inputChange = (v) => {
		console.log(v)
		if (!v.target.value) {
			setLeftArr(oriList)
		} else {
			setLeftArr(
				oriList.filter((item) => item.userName.indexOf(v.target.value) > -1)
			)
		}
	}
	return (
		<Modal
			width={900}
			className="single-list-modal"
			open={visible}
			centered
			onCancel={onCancel}
			// onOk={_onOk}
			title={title}
			footer={[]}
		>
			<>
				<div style={{ height: 400 }} className="target-contain">
					<div className="target-item">
						<Input
							prefix={<span className="iconfont search-icon">&#xe6ac;</span>}
							onChange={inputChange}
							placeholder="姓名、手机号搜索"
						></Input>
						<div onClick={selectAllClick} className="left-all">
							<img
								className="select-icon"
								src={
									selectAll && oriList.length === targetKeys.length
										? select_yes
										: select_empty
								}
							></img>
							<span>全部</span>
						</div>
						<List split={false}>
							<VirtualList
								data={leftArr}
								height={310}
								itemHeight={48}
								itemKey="uuid"
							>
								{(item, index) => (
									<List.Item key={item.uuid}>
										<div
											onClick={() => leftItemClick(item)}
											className="list-item"
										>
											<div className="left-view">
												<img
													className="select-icon"
													src={
														targetKeys.includes(item.uuid)
															? select_yes
															: select_empty
													}
												></img>
												<div
													className="image"
													style={{ background: linears[index % 3] }}
												>
													<span className="title">
														{item.userName.slice(-2)}
													</span>
												</div>

												<span>{item.userName}</span>
											</div>
										</div>
									</List.Item>
								)}
							</VirtualList>
						</List>
					</div>
					<div className="line"></div>
					<div className="target-item">
						<div className="right-top-view">
							<span>已选:</span>
							<div onClick={() => setTargetKeys([])} className="clear-all">
								清空
							</div>
						</div>
						<List split={false}>
							<VirtualList
								data={rightList}
								height={345}
								itemHeight={48}
								itemKey="uuid"
							>
								{(item, index) => (
									<List.Item key={item.uuid}>
										<div className="right-list-item">
											<div className="left">
												<div
													className="image"
													style={{ background: linears[index % 3] }}
												>
													<span className="title">
														{item.userName.slice(-2)}
													</span>
												</div>
												<div className="text-view">
													<span>{item.userName}</span>
													<span className="sub-title">{item.companyName}</span>
												</div>
											</div>
											<span
												onClick={() => rigthDelete(item.uuid)}
												className="iconfont close"
											>
												&#xe72b;
											</span>
										</div>
									</List.Item>
								)}
							</VirtualList>
						</List>
					</div>
				</div>
				<ModalBtns onCancel={onCancel} onClick={_onOk} />
			</>
		</Modal>
	)
}
