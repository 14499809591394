import { Button } from 'antd'
export function FormBtns({
	onCancel,
	leftText = '确定',
	rightText = '取消',
	style = {},
}) {
	return (
		<div style={style} className="form-bottom-btns">
			<Button className="width-80 margin-12" htmlType="submit" type="primary">
				{leftText}
			</Button>
			<Button onClick={onCancel} className="width-80">
				{rightText}
			</Button>
		</div>
	)
}
