import React from 'react'
import { Tooltip } from 'antd'
import { register } from '@antv/x6-react-shape'
export class ConditionNode extends React.Component {
	render() {
		// console.log(this.props.node.store.data.data)
		const { nodeName, nodeContent, power, canSave } =
			this.props.node.store.data.data
		let toolText = ''
		if (nodeContent) {
			let tmpArr = nodeContent.split('或')
			if (tmpArr.length > 1) {
				toolText = tmpArr.map((item, index) => {
					if (tmpArr.length === index + 1) {
						return <span key={item}>{item}</span>
					}
					return (
						<span key={item}>
							{item}
							<br />或<br />
						</span>
					)
				})
			} else {
				toolText = nodeContent
			}
		}
		return (
			<div className="condition-node">
				<div className="top-view">
					<Tooltip title={nodeName}>
						<span className="green-text">{nodeName}</span>
					</Tooltip>
					<span className="green-text">{'优先级' + power}</span>
				</div>
				<div className="line"></div>
				<div className="condition-view">
					<Tooltip title={toolText}>
						<div
							style={{ color: nodeContent ? '#1f2329' : '#A1A5AD' }}
							className="condition-text"
						>
							{nodeContent || '请设置条件'}
						</div>
					</Tooltip>
					<span className="iconfont expand">&#xe72f;</span>
				</div>
				{!canSave && <span className="iconfont error">&#xe6e5;</span>}
			</div>
		)
	}
}
register({
	shape: 'condition-node',
	width: 200,
	height: 78,
	component: ConditionNode,
})
