import {
	Drawer,
	Form,
	Radio,
	Input,
	Space,
	Tooltip,
	Select,
	Checkbox,
	message,
} from 'antd'
import { useState, useEffect } from 'react'
import { add_man, tip_image, member_close } from '../assets/index'
import { FormBtns } from './FormBtns'
import { definition_groupList, definition_userList } from '../api/x6'
import { DrawerEditTitle } from './DrawerEditTitle'
import { TargetItem } from './TargetItem'
import { TargetModal } from './TargetModal'
import {
	types,
	typesOptions,
	roles,
	manyMans,
	emptyOption,
	empty_text,
	endOption,
	appointOptions,
	bookOptions,
	departmentOptions,
	initApproveFnc,
	twoMans,
} from './approver.data'
import { linears } from '../App.data'
const { Item } = Form

// isSequential "1": 依次 "2": 会签 "3" 或签
export function ApproverDrawer({ visible, onClose, onFinish, initialApprove }) {
	const [typeTwo, setTypeTwo] = useState(0)

	const [title, setTitle] = useState(initialApprove.nodeName || '审批人')
	const [canEdit, setCanEdit] = useState(false)
	const [roleOptions, setRoleOptions] = useState([])

	const [userList, setUserList] = useState([])
	const [singleModal, setSingleModal] = useState(false)
	console.log(initialApprove.nodeJson)
	let tmpInit = initApproveFnc(initialApprove.nodeJson)
	console.log(tmpInit)
	const [type, setType] = useState(tmpInit.approvalType) // 审批类型
	const [role, setRole] = useState(tmpInit.propertyType) // 审批人
	const [members, setMembers] = useState(tmpInit.users) // 指定成员多人
	const [approveType, setApproveType] = useState(tmpInit.isPass) // 审批人为空时
	const [endType, setEndType] = useState(tmpInit.approveEnd) // 多级审批终点
	const [targetModal, setTargetModal] = useState(false)
	useEffect(() => {
		definition_groupList()
			.then((res) => setRoleOptions(res.records))
			.catch(() => {})
		definition_userList()
			.then((res) => setUserList(res.records))
			.catch(() => {})
	}, [])
	const _onFinish = (v) => {
		if (role === 0 && members.length === 0) {
			return message.info('请添加成员')
		}
		if (role === 0) {
			// nodeJson
			let memberText = members.map((item) => item.userName).join(',')
			if (members.length > 1) {
				if (v.isSequential === '2') {
					memberText = members.length + '人(会签)'
				} else if (v.isSequential === '3') {
					memberText = members.length + '人(或签)'
				}
			}
			onFinish({
				nodeJson: { ...v, users: members },
				nodeContent: memberText,
				nodeName: title,
			})
		} else if (role === 1) {
			if (approveType === 3) {
				let tmpUserList = userList.filter((item) => item.uuid === v.mutiUserId)
				onFinish({
					nodeJson: { ...v, singleUser: tmpUserList },
					nodeContent: '连续多级上级',
					nodeName: title,
				})
			} else {
				onFinish({
					nodeJson: v,
					nodeContent: '连续多级上级',
					nodeName: title,
				})
			}
		} else if (role === 2) {
			let toBeApproved = v.toBeApproved.length === 1 ? 1 : 0
			if (approveType === 3) {
				let tmpUserList = userList.filter(
					(item) => item.uuid === v.departUserId
				)
				onFinish({
					nodeJson: { ...v, singleUser: tmpUserList, toBeApproved },
					nodeContent: '部门负责人',
					nodeName: title,
				})
			} else {
				onFinish({
					nodeJson: { ...v, toBeApproved },
					nodeContent: '部门负责人',
					nodeName: title,
				})
			}
		} else if (role === 3) {
			if (approveType === 3) {
				let tmpUserList = userList.filter((item) => item.uuid === v.upUserId)
				onFinish({
					nodeJson: { ...v, singleUser: tmpUserList },
					nodeContent: '直属上级',
					nodeName: title,
				})
			} else {
				onFinish({
					nodeJson: v,
					nodeContent: '直属上级',
					nodeName: title,
				})
			}
		} else if (role === 4) {
			if (approveType === 3) {
				let tmpUserList = userList.filter((item) => item.uuid === v.roleUserId)
				onFinish({
					nodeJson: { ...v, singleUser: tmpUserList },
					nodeContent: '角色',
					nodeName: title,
				})
			} else {
				onFinish({
					nodeJson: v,
					nodeContent: '角色',
					nodeName: title,
				})
			}
		} else if (role === 5) {
			onFinish({
				nodeJson: v,
				nodeContent: '提交人本人',
				nodeName: title,
			})
		}
	}
	const singleOk = (keys = []) => {
		setTargetModal(false)
		let tmpArr = []
		keys.map((item) => {
			tmpArr.push(userList.filter((v) => v.uuid === item)[0])
		})
		setMembers(tmpArr.slice(0, 25))
	}
	const memberDelete = (key) =>
		setMembers(members.filter((item) => item.uuid !== key))
	return (
		<Drawer
			width={532}
			title={
				<DrawerEditTitle
					title={title}
					canEdit={canEdit}
					onChange={(v) => setTitle(v.target.value)}
					onClick={() => setCanEdit(true)}
					onBlur={() => setCanEdit(false)}
				/>
			}
			open={visible}
			onClose={onClose}
		>
			<Form
				initialValues={tmpInit}
				className="x6-approver-form"
				layout="vertical"
				onFinish={_onFinish}
			>
				<Item name="approvalType" label="审批类型">
					<Radio.Group
						onChange={(v) => setType(v.target.value)}
						options={types}
					></Radio.Group>
				</Item>
				{type === 0 && (
					<>
						<Item label="审批类型">
							<Radio.Group
								onChange={(v) => setTypeTwo(v.target.value)}
								options={typesOptions}
								defaultValue={typeTwo}
								buttonStyle="solid"
								optionType="button"
							></Radio.Group>
						</Item>
						{typeTwo === 0 && (
							<>
								<div className="approver-view">
									<div className="person-view">审批人</div>
									<div className="padding">
										<Item name="propertyType">
											<Radio.Group onChange={(v) => setRole(v.target.value)}>
												<div className="wrap">
													{roles.map((item) => (
														<Radio
															disabled={item.disabled}
															style={{
																width: 130,
																marginTop: 10,
															}}
															key={item.label}
															value={item.value}
														>
															{item.hasTip ? (
																<div className="item">
																	<span>{item.label}</span>
																	<Tooltip title={item.content}>
																		<span className="iconfont right-tip">
																			&#xe746;
																		</span>
																	</Tooltip>
																</div>
															) : (
																item.label
															)}
														</Radio>
													))}
												</div>
											</Radio.Group>
										</Item>
										<div className="line"></div>

										{role === 0 && (
											<Item label="添加成员（不能超过25人）">
												<div className="members">
													<div
														onClick={() => setTargetModal(true)}
														className="add-man"
													>
														<img className="image" src={add_man}></img>
													</div>
													{members.map((item, index) => {
														return (
															<div key={item.uuid} className="members-item">
																<div
																	className="circle"
																	style={{ background: linears[index % 3] }}
																>
																	<span className="text">
																		{item.userName.slice(-2)}
																	</span>
																</div>
																<span className="title">{item.userName}</span>
																<img
																	onClick={() => memberDelete(item.uuid)}
																	className="close"
																	src={member_close}
																></img>
															</div>
														)
													})}
												</div>
											</Item>
										)}
										{role === 1 && (
											<Item name="approveEnd" label="审批终点">
												<Radio.Group
													onChange={(v) => setEndType(v.target.value)}
													options={endOption}
												></Radio.Group>
											</Item>
										)}
										{role === 1 && endType === 1 && (
											<Item
												name="endRole"
												label="添加角色（同时是主管线上的主管）"
											>
												<Select placeholder="请选择">
													{roleOptions.map((item) => (
														<Select.Option
															key={item.roleCode}
															value={item.roleCode}
														>
															{item.roleName}
														</Select.Option>
													))}
												</Select>
											</Item>
										)}
										{role === 1 && endType === 1 && (
											<Item label="指定审批层级">
												<div className="tier">
													<div className="left">不超过发起人向上的</div>
													<div className="right">
														<Item name="endLevel" style={{ marginBottom: 0 }}>
															<Select placeholder="请选择">
																{appointOptions.map((item) => (
																	<Select.Option
																		key={item.value}
																		value={item.value}
																	>
																		{item.label}
																	</Select.Option>
																))}
															</Select>
														</Item>
													</div>
												</div>
											</Item>
										)}
										{role === 1 && endType === 2 && (
											<Item label="指定审批上级">
												<div className="tier">
													<div className="left">通讯录中的</div>
													<div className="right">
														<Item
															name="contactLeve"
															style={{ marginBottom: 0 }}
														>
															<Select placeholder="请选择">
																{bookOptions.map((item) => (
																	<Select.Option
																		key={item.value}
																		value={item.value}
																	>
																		{item.label}
																	</Select.Option>
																))}
															</Select>
														</Item>
													</div>
												</div>
											</Item>
										)}
										{role === 2 && (
											<Item label="指定审批主管">
												<div className="tier">
													<div className="left">发起人的</div>
													<div className="right">
														<Item style={{ marginBottom: 0 }} name="level">
															<Select placeholder="请选择">
																{departmentOptions.map((item) => (
																	<Select.Option
																		key={item.value}
																		value={item.value}
																	>
																		{item.label}
																	</Select.Option>
																))}
															</Select>
														</Item>
													</div>
												</div>
											</Item>
										)}
										{role === 2 && (
											<Item name="toBeApproved">
												<Checkbox.Group>
													<Checkbox value={0}>
														找不到主管时，由上级主管代审批
													</Checkbox>
												</Checkbox.Group>
											</Item>
										)}
										{role === 3 && (
											<Item label="指定审批上级">
												<div className="tier">
													<div className="left">发起人的</div>
													<div className="right">
														<Item name="level" style={{ marginBottom: 0 }}>
															<Select placeholder="请选择">
																{departmentOptions.map((item) => (
																	<Select.Option
																		key={item.value}
																		value={item.value}
																	>
																		{item.label}
																	</Select.Option>
																))}
															</Select>
														</Item>
													</div>
												</div>
											</Item>
										)}
										{role === 4 && (
											<Item
												name="role"
												rules={[{ required: true, message: '请添加角色' }]}
												label="添加角色"
											>
												<Select placeholder="请选择">
													{roleOptions.map((item) => (
														<Select.Option
															key={item.roleCode}
															value={item.roleCode}
														>
															{item.roleName}
														</Select.Option>
													))}
												</Select>
											</Item>
										)}
										{role === 5 && (
											<Item>
												<span>发起人自己将作为审批人处理审批单</span>
											</Item>
										)}
									</div>
								</div>
								{((role === 0 && members.length >= 2) || role === 2) && (
									<Item name="isSequential" label="多人审批时采用的审批方式">
										<Radio.Group>
											<Space direction="vertical">
												{manyMans.map((item) => (
													<Radio key={item.label} value={item.value}>
														{item.label}
													</Radio>
												))}
											</Space>
										</Radio.Group>
									</Item>
								)}
								{role === 4 && (
									<Item
										name="roleIsSequential"
										label="多人审批时采用的审批方式"
									>
										<Radio.Group>
											<Space direction="vertical">
												{twoMans.map((item) => (
													<Radio key={item.label} value={item.value}>
														{item.label}
													</Radio>
												))}
											</Space>
										</Radio.Group>
									</Item>
								)}
								{role !== 0 && role !== 5 && (
									<Item
										name="isPass"
										label={
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<span>审批人为空时</span>
												<Tooltip title={empty_text}>
													<span className="iconfont right-tip">&#xe746;</span>
												</Tooltip>
											</div>
										}
									>
										<Radio.Group
											onChange={(v) => setApproveType(v.target.value)}
										>
											{emptyOption.map((item) => (
												<Radio key={item.label} value={item.value}>
													{item.hasTip ? (
														<div className="empty-item">
															<span>{item.label}</span>
															<Tooltip title={item.content}>
																<span className="iconfont right-tip">
																	&#xe746;
																</span>
															</Tooltip>
														</div>
													) : (
														item.label
													)}
												</Radio>
											))}
										</Radio.Group>
									</Item>
								)}

								<TargetItem
									name="mutiUserId"
									userList={userList}
									isShow={role === 1 && approveType === 3}
								/>
								<TargetItem
									name="departUserId"
									userList={userList}
									isShow={role === 2 && approveType === 3}
								/>
								<TargetItem
									name="upUserId"
									userList={userList}
									isShow={role === 3 && approveType === 3}
								/>
								<TargetItem
									name="roleUserId"
									userList={userList}
									isShow={role === 4 && approveType === 3}
								/>
							</>
						)}
					</>
				)}
				<FormBtns onCancel={onClose} leftText="保存" />

				{targetModal && (
					<TargetModal
						title="选择审批负责人"
						visible={targetModal}
						onCancel={() => setTargetModal(false)}
						onOk={singleOk}
						oriList={userList}
						tList={members.map((item) => item.uuid)}
					></TargetModal>
				)}
			</Form>
		</Drawer>
	)
}
