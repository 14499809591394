import { zoom, zoomAdd, zoomSub } from '../assets/index'
import { useState } from 'react'
import { Tooltip } from 'antd'
export function ZoomView({ graphState }) {
	const [number, setNumber] = useState(100)
	const subClick = () => {
		if (number > 25) {
			setNumber(number - 25)
			graphState.zoom(-0.25)
		}
	}
	const addClick = () => {
		if (number < 300) {
			setNumber(number + 25)
			graphState.zoom(0.25)
		}
	}
	const resetClick = () => {
		graphState.zoom(-(number - 100) / 100)
		setNumber(100)
	}
	return (
		<div className="zoom-view">
			<Tooltip title="放大">
				<div
					onClick={subClick}
					className="image-view"
					style={{ marginLeft: 12 }}
				>
					<img src={zoomSub} className="zoom"></img>
				</div>
			</Tooltip>

			<span className="number">{`${number}%`}</span>
			<Tooltip title="缩小">
				<div
					onClick={addClick}
					className="image-view"
					style={{ marginLeft: 8 }}
				>
					<img src={zoomAdd} className="zoom"></img>
				</div>
			</Tooltip>
			<Tooltip title="返回初始位置">
				<div
					onClick={resetClick}
					className="image-view"
					style={{ marginLeft: 12 }}
				>
					<img src={zoom} className="zoom"></img>
				</div>
			</Tooltip>
		</div>
	)
}
