import Cookies from 'js-cookie'

const getCookie = (key) => {
	return Cookies.get(key)
}

const setCookie = (key, value, expires) => {
	Cookies.set(key, value, { expires: expires ? expires : 365 })
}

const removeCookie = (key) => {
	Cookies.remove(key)
}

export { getCookie, setCookie, removeCookie }
